import {
    Employee,
    NavigationRoute,
} from "../types";

export enum ActionType {
    SetIsAuth = "SET_IS_AUTHENTICATED",
    SetFetchingData = "SET_FETCHING_DATA",
    SetShowFullMenu = "SET_SHOW_FULL_MENU",
    SetCurrentPage = "SET_CURRENT_PAGE",
    SetActivePage = "SET_ACTIVE_PAGE",

    SetUserNavLinks = "SET_NAV_LINKS",
    SetUserInfo = "SET_USER_INFO",
    SetUserLink = "SET_USER_LINK",
    SetConfigRaw = "SET_CONFIG_RAW",
}

interface ISetIsAuth {
    type: ActionType.SetIsAuth;
    payload: boolean;
}

interface ISetFetchingData {
    type: ActionType.SetFetchingData;
    payload: boolean;
}

interface ISetShowFullMenu {
    type: ActionType.SetShowFullMenu;
    payload: boolean;
}

interface ISetCurrentPage {
    type: ActionType.SetCurrentPage;
    payload: any;
}

interface ISetUserNavLinks {
    type: ActionType.SetUserNavLinks;
    payload: NavigationRoute[];
}

interface ISetUserInfo {
    type: ActionType.SetUserInfo;
    payload: Employee;
}

interface ISetUserLink {
    type: ActionType.SetUserLink;
    payload: NavigationRoute;
}

interface ISetConfigRaw {
    type: ActionType.SetConfigRaw;
    payload: any
}

interface ISetActivePage {
    type: ActionType.SetActivePage;
    payload: string;
}

export type Actions =
    | ISetIsAuth
    | ISetFetchingData
    | ISetShowFullMenu
    | ISetCurrentPage
    | ISetUserNavLinks
    | ISetUserInfo
    | ISetUserLink
    | ISetConfigRaw
    | ISetActivePage;

export const setIsAuth = (response: boolean): ISetIsAuth => ({
    type: ActionType.SetIsAuth,
    payload: response,
});

export const setFetchingData = (response: boolean): ISetFetchingData => ({
    type: ActionType.SetFetchingData,
    payload: response,
});

export const setShowFullMenu = (response: boolean): ISetShowFullMenu => ({
    type: ActionType.SetShowFullMenu,
    payload: response,
});

export const setCurrentPage = (response: any): ISetCurrentPage => ({
    type: ActionType.SetCurrentPage,
    payload: response,
});

export const setUserNavLinks = (response: NavigationRoute[]): ISetUserNavLinks  => {
    return {
        type: ActionType.SetUserNavLinks,
        payload: response,
}};

export const setUserInfo = (response: Employee): ISetUserInfo => ({
    type: ActionType.SetUserInfo,
    payload: response,
});

export const setConfigRaw = (response: any) => ({
    type:ActionType.SetConfigRaw,
    payload: response
})

export const setUserLink = (response: NavigationRoute): ISetUserLink => ({
    type: ActionType.SetUserLink,
    payload: response,
});

export const setActivePage = (response: string): ISetActivePage => ({
    type: ActionType.SetActivePage,
    payload: response,
});
