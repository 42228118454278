import React from "react";
import PerfectScrollbar from "react-perfect-scrollbar";

import { NavigationRoute } from "../types";

import { AppIcon } from "./SVGFiles";
import MenuEdgeIco from "../assets/ico/menu-edge.svg";
import { Button } from "antd";
import { AppContext } from "../context";

import "react-perfect-scrollbar/dist/css/styles.css";
import { useMsal } from "@azure/msal-react";
import { getPostLogoutUrlByEnv } from "../utils/getUrl";

interface Props {
    showFullMenu: boolean;
    onCollapseBtnClick: () => void;
}

export const AppNavigator: React.FC<Props> = ({
    showFullMenu,
    onCollapseBtnClick,
}) => {
    const { state } = React.useContext(AppContext);
    const [currentaPage, setCurrentaPage] =
        React.useState<string>("Config Portal");
    const { instance, inProgress, accounts } = useMsal();

    React.useEffect(() => {
        const body = document.body || document.getElementsByTagName("body");
        body.classList.add("ss-ss-app");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        if (showFullMenu) {
            document.body.classList.add("menu-show-full");
        } else {
            document.body.classList.remove("menu-show-full");
        }
    }, [showFullMenu]);

    const logout = () => {
        instance.logoutRedirect({
            account: accounts[0],
            postLogoutRedirectUri: getPostLogoutUrlByEnv()
        })
    }

    return (
        <div className="ss-app-navigator">
            <div className="ss-app-navigator-wrapper">
                <PerfectScrollbar options={{ suppressScrollX: true }}>
                    {state.navLinks.length
                        ? state.navLinks
                            .filter((nav: any) => nav.enabled)
                            .map((navProps: any, i: number) => {
                                const isActive = navProps.id === currentaPage;
                                return (
                                    <AppNavLink
                                        {...navProps}
                                        isActive={isActive}
                                        onClick={setCurrentaPage}
                                        key={i}
                                    />
                                );
                            })
                        : null}
                    <AppNavLink
                        onClick={() => logout()}
                        link={process.env.REACT_APP_LOGOUT_URL as string}
                        label="Logout"
                        icon="logout"
                        id="logout"
                    />
                </PerfectScrollbar>
            </div>
            <AppNavLink
                link="#"
                label="Collapse"
                icon={showFullMenu ? "left-circle" : "right-circle"}
                id="collapse"
                className="ss-app-collapse-btn"
                onClick={() => onCollapseBtnClick()}
            />
            <Button
                onClick={() => onCollapseBtnClick()}
                type="link"
                className="ss-app-navigator-close"
                icon={
                    <AppIcon
                        height="36"
                        width="36"
                        asset="close"
                        stroke="#344356"
                    />
                }
            />
        </div>
    );
};

interface NavLink extends NavigationRoute {
    className?: string;
    isActive?: boolean;
    onClick?: (id: string) => void;
}

const AppNavLink: React.FC<NavLink> = ({
    label,
    icon,
    id,
    link,
    isActive = false,
    className,
    onClick,
    subLinks,
}) => {
    const [subLinkY, setSubLinkY] = React.useState<number | undefined>();
    React.useEffect(() => {
        if (subLinkRef.current) {
            setSubLinkY(subLinkRef.current.clientHeight);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        if (subLinkRef.current && linkRef.current) {
            if (isActive) {
                const height =
                    subLinkRef.current.clientHeight +
                    linkRef.current.clientHeight +
                    6;
                setSubLinkY(height);
            } else {
                setSubLinkY(undefined);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isActive]);

    const linkRef = React.createRef<HTMLDivElement | null>();
    const subLinkRef = React.createRef<HTMLDivElement | null>();
    let linkAttr: any = {
        role: "button",
        onClick: () => onClick && onClick(id),
        className: `ss-app-nav-item${
            isActive !== undefined ? (isActive ? " active" : "") : ""
        }`,
        href: subLinks?.length ? undefined : link,
        style: subLinkY ? { height: subLinkY } : undefined,
    };
    const linkContent = (
        <>
            <AppIcon asset={icon} />
            <span className="ss-app-nav-item-label">{label}</span>
            <span
                className="ss-app-nav-item-edge ss-app-nav-item-top-edge"
                style={{ backgroundImage: `url(${MenuEdgeIco})` }}
            />
            <span
                className="ss-app-nav-item-edge ss-app-nav-item-bottom-edge"
                style={{ backgroundImage: `url(${MenuEdgeIco})` }}
            />
        </>
    );

    return (
        <div className={`link-item-wrapper ${className || ""}`}>
            {subLinks?.length ? (
                <div {...linkAttr} ref={linkRef}>
                    <div>
                        {linkContent}{" "}
                        <span className="ss-app-sub-link-arrow">
                            <AppIcon
                                asset="arrow-down"
                                height="16"
                                width="16"
                                stroke="#ffffff"
                                className="sub-link-arrow"
                            />
                        </span>
                    </div>
                    <SubLinks subLinks={subLinks} ref={subLinkRef} />
                </div>
            ) : (
                <a {...linkAttr}>{linkContent}</a>
            )}
        </div>
    );
};

interface ISubLinks {
    subLinks: NavigationRoute[];
}

const SubLinks = React.forwardRef<HTMLDivElement | null, ISubLinks>(
    ({ subLinks }, ref) => {
        return (
            <div className="ss-app-nav-sub-links" ref={ref}>
                {subLinks.map((navProps, i) => {
                    return (
                        <a
                            role={"button"}
                            className="ss-app-nav-item"
                            key={i}
                            href={navProps.link}
                        >
                            <AppIcon asset={navProps.icon} />
                            <span className="ss-app-nav-item-label">
                                {navProps.label}
                            </span>
                        </a>
                    );
                })}
            </div>
        );
    }
);
