import {AppstoreAddOutlined, FrownFilled} from "@ant-design/icons";

let outputArray: any = [];
let tdKeyCounter = 0;
let layers: any = [];
function loopNodes(key: string, nodes: any, metaDataNodes: any, category?: string) {
    if (metaDataNodes && metaDataNodes.metadata && metaDataNodes.metadata.tags && !category) {
        category = metaDataNodes.metadata.tags.toString()
    }
    for (const [nKey, value] of Object.entries(nodes)) {
        if ((value as any).nodes) {
            let metaDataLoopNode
            if (metaDataNodes.nodes && metaDataNodes.nodes['@{}']) {
                metaDataLoopNode = metaDataNodes.nodes['@{}'].nodes
            } else {
                if (metaDataNodes[nKey]) {
                    metaDataLoopNode = metaDataNodes[nKey].nodes
                } else {
                    metaDataLoopNode = metaDataNodes.nodes[nKey].nodes
                }
            }
            loopNodes(key + ':' + nKey, (value as any).nodes, metaDataLoopNode, category)
        } else {
            const keyString = key + ':' + nKey

            let description = metaDataNodes?.metadata?.description
            let valueType = metaDataNodes?.metadata?.valueMetadata?.valueType
            if (metaDataNodes.nodes && metaDataNodes.nodes['@{}']) {
                description = metaDataNodes.nodes['@{}'].nodes[nKey].metadata.description
                valueType = metaDataNodes.nodes['@{}'].nodes[nKey].metadata.valueMetadata.valueType
            } else if (metaDataNodes && metaDataNodes['@{}']) {
                description = metaDataNodes['@{}'].metadata.description
                valueType = metaDataNodes['@{}'].metadata.valueMetadata.valueType
            } else if (metaDataNodes && metaDataNodes['@[]']) {
                description = metaDataNodes['@[]'].metadata.description
                valueType = metaDataNodes['@[]'].metadata.valueMetadata.valueType
            } else if (metaDataNodes && metaDataNodes[nKey]) {
                description = metaDataNodes[nKey].metadata.description
                valueType = metaDataNodes[nKey].metadata.valueMetadata.valueType
            } else if (metaDataNodes && typeof metaDataNodes.nodes !== 'string' && metaDataNodes.nodes[nKey]) {
                description = metaDataNodes.nodes[nKey].metadata.description
                valueType = metaDataNodes.nodes[nKey].metadata.valueMetadata.valueType
            } else {
                // console.log('break ehre', metaDataNodes)
                // debugger;
                console.error('did not match correctly for metadata nodes')
                console.error('keyString', keyString)
                console.error('metaDtaNodes', metaDataNodes)
            }
            tdKeyCounter++
            let leafValue = (value as any).values[0]
            let mappedValues = [];
            let company = ''
            let companies = []
            let isDefault = 'true';
            for (const [vKey, leaf] of Object.entries((value as any).values as any)) {
                if (vKey as any === '0' ) {
                    isDefault = 'true';
                } else {
                    isDefault = 'false';
                }
                // todo: fix magic number 1. 0 is default value, 1 is global value, need to check if the string is "global"
                if (vKey as any > 1 && layers[vKey]) {
                    company = layers[vKey].company
                    companies.push(layers[vKey].company)
                }

                // TODO: choose one from default and global
                if (!leafValue) {
                    leafValue = leaf
                }
                mappedValues.push ({
                    layerIndex: vKey,
                    companyNumber: layers[vKey].company,
                    layerKey: layers[vKey].key,
                    value: leaf,
                    selected: false
                })
            }

            outputArray.push({
                key: tdKeyCounter.toString(),
                name: keyString,
                value: leafValue,
                values: mappedValues,
                metaData: description,
                valueType: valueType,
                description: description,
                defValue: isDefault,
                companies: companies.length > 0 ? companies : null,
                category: category
            })
        }
    }
}

export function formatData(datas: any) {
    // let newtree = formatTreeFrontEnd(datas.configRoot, datas.metadataRoot)
    // console.log('new tree', newtree)
    outputArray = [];
    tdKeyCounter = 0;
    // const affinity = datas.configRoot.nodes.Affinity;
    // const affinityMetaData: any = datas.metadataRoot.nodes.Affinity;
    const configRootNodes = datas.configRoot.nodes;
    const metaDataRootNodes = datas.metadataRoot.nodes;
    layers = datas.layers;

    for (const [affinityKey, affinityRoot] of Object.entries(configRootNodes as {})) {
        // console.log('affinityRoot', affinityRoot)
        for (const [key1, value1] of Object.entries((affinityRoot as any).nodes as {})) {
            // console.log('value1', value1)
            if ((value1 as any).nodes) {
                if (metaDataRootNodes[affinityKey].nodes[key1]) {
                    loopNodes( `${affinityKey}:${key1}` , (value1 as any).nodes, metaDataRootNodes[affinityKey].nodes[key1])
                } else if (metaDataRootNodes[affinityKey].nodes['@{}']) {
                    loopNodes( `${affinityKey}:${key1}@{}` , (value1 as any).nodes, metaDataRootNodes[affinityKey].nodes['@{}'])
                }

            }
        }
    }

    return outputArray
}

// const treeData = [
//     {
//         value: 'parent 1',
//         title: 'parent 1',
//         children: []
//     }
// ]

let indentKeyArray: number[] = [0]
// let keyArrayIndex = 0
export function formatTreeFrontEnd(originalTree: any, metaData: any, keyArrayIndex: number = 0, ) {
    let newTree: any = {}
    // let nodesArr = []
    let entryTarget = originalTree;
    if (originalTree.key && originalTree.children) {
        if (indentKeyArray.length > 0) {
            newTree.key = indentKeyArray.slice(0, keyArrayIndex).toString()
        } else {
            newTree.key = '0'
        }
        newTree.checkable = false;
        newTree.title = originalTree.key
        entryTarget = originalTree.children
    }
    var arrayIndexAdded = false;
    let newKeydepth = keyArrayIndex;
    for (const [treeKey, val] of Object.entries(entryTarget as {})) {
        if (treeKey === 'nodes') {
            if (metaData && metaData[treeKey] && metaData[treeKey]['@{}']) {
                newTree.wildCard = true;
                newTree.wildCardNodes = metaData[treeKey]['@{}'].nodes
                newTree.selectable = true;
                newTree.icon = <AppstoreAddOutlined />;

                indentKeyArray[newKeydepth] = 0
                let child = Object.entries(val as {}).map(entry => {
                    indentKeyArray[newKeydepth] = indentKeyArray[newKeydepth] + 1
                    // indentKey.push(0)
                    return formatTreeFrontEnd({
                        key: entry[0],
                        children: entry[1]
                    // }, {[entry[0]]: metaData[treeKey]['@{}']});
                    }, metaData[treeKey]['@{}'], keyArrayIndex + 1);
                })
                newTree.children = child
            } else {
                // if (newKeydepth === 0) {
                //     debugger;
                // }
                indentKeyArray[newKeydepth] = 0
                let child = Object.entries(val as {}).map(entry => {
                    indentKeyArray[newKeydepth] = indentKeyArray[newKeydepth] + 1
                    // return formatTreeFrontEnd({[entry[0]]: entry[1]}, {[entry[0]]: metaData[treeKey][entry[0]]});
                    return formatTreeFrontEnd({
                        key: entry[0],
                        children: entry[1]
                    }, metaData[treeKey][entry[0]], newKeydepth + 1);
                })

                newTree.children = child
            }

        } else {
            if((val as any).nodes) {
                // let childWithNodes =
                // newTree.key = treeKey
                // newTree.children = formatTreeFrontEnd(val, metaData[treeKey])
                // indentKey[indentKey.length - 1] = indentKey[indentKey.length - 1] + 1;
                newTree.children = formatTreeFrontEnd(val, undefined, keyArrayIndex)
            } else {
                // newTree.key = treeKey;
                // newTree.title = treeKey;
                newTree.value = val;
                // newTree[treeKey] = val
            }

        }
    }

    // newTree.children = nodesArr
    return newTree
}

export function getCompanyFromLayer(layerNumberStr: string) {

}