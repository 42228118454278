import moment from "moment";
import React, { createContext, useReducer } from "react";

import {
    NavigationRoute,
    Employee,
} from "../types";
import { APP_DATE_FORMAT, genDateDisplay } from "../utils/date";
import {
    Actions,
    ActionType,
    setFetchingData,
} from "./actions";

interface IState {
    isAuth: boolean;
    fetchingData: boolean;
    showFullMenu: boolean;
    currentPage: any;
    latestYear: number;
    configRaw: any;
    navLinks: NavigationRoute[];
    user?: Employee;
    userLink?: NavigationRoute;
    activePage: string;

}

const initialState: IState = {
    isAuth: false,
    fetchingData: false,
    showFullMenu: window.innerWidth > 1200, // MENU open in larger screen
    currentPage: "",
    latestYear: 0,
    navLinks: [],
    configRaw: {},
    activePage: "dashboard",

};

interface IAppContext {
    state: IState;
    dispatch: React.Dispatch<Actions>;
}

const AppContext = createContext<IAppContext>({
    state: initialState,
    dispatch: () => null,
});

const { Provider } = AppContext;

const reducer = (state: IState, action: Actions) => {
    switch (action.type) {
        case ActionType.SetIsAuth:
            return {
                ...state,
                isAuth: action.payload,
            };

        case ActionType.SetFetchingData:
            return {
                ...state,
                fetchingData: action.payload,
            };

        case ActionType.SetShowFullMenu:
            return {
                ...state,
                showFullMenu: action.payload,
            };

        case ActionType.SetCurrentPage:
            return {
                ...state,
                currentPage: action.payload,
            };


        case ActionType.SetUserNavLinks:
            return {
                ...state,
                navLinks: action.payload,
            };

        case ActionType.SetUserInfo:
            return {
                ...state,
                user: action.payload,
            };

        case ActionType.SetUserLink:
            return {
                ...state,
                userLink: action.payload,
            };
        case ActionType.SetConfigRaw:
            return {
                ...state,
                configRaw: action.payload,
            };
        case ActionType.SetActivePage:
            return {
                ...state,
                activePage: action.payload,
            };

        default:
            return state;
    }
};

const AppProvider = ({ children }: { children: JSX.Element }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    return (
        <Provider
            value={{
                state,
                dispatch
            }}
        >
            {children}
        </Provider>
    );
};

export { AppContext, AppProvider };
