import {Button, Checkbox, Divider, Input, Modal, Select, Tag} from "antd";

import React, {useEffect, useState} from "react";
import {MetadataModalData} from "../configType";
import {EditTags} from "./EditTags";
import _ from "lodash";
import { ModeOption } from "./EditMetaDataValueModal";
import { ValueType } from "../constant";
const { TextArea } = Input;
interface IProps {
    modalDataInput: any;
    // isBranch: boolean;
    mode: ModeOption;
    onUpdateModalData: (modalData: MetadataModalData) => void;
}

export const EditMetaData = (props: IProps) => {
    // const { state, dispatch } = React.useContext(AppContext);

    const [modalData, setModalData] = useState<MetadataModalData>({} as MetadataModalData)

    useEffect(() => {
        setModalData(props.modalDataInput)
    }, [props.modalDataInput])

    const handleKeyPress = (event: any) => {
        // prevent : in name // 58 is the character code for ":"
        if (event.charCode === 58) { 
          event.preventDefault();
        }
    };

    function updateData(newVal: string) {
        console.log('new val', newVal)
        let newData = { ...modalData }
        newData.defaultValue = newVal
        setModalData(newData)
        props.onUpdateModalData(newData)
    }

    const ValueInputByType = () => {
        const type = props.modalDataInput.type
        console.log('modalData.type', type)
        if (type === 'String' || type === 'Integer' || type === 'Double' || type === 'Uri' || type === 'Guid') {
            return <div className="d-flex flex-jbetween mt-2">
                        <span>Default Value</span>
                        <Input style={{ 'width': '300px' }}
                            value={modalData.defaultValue}
                            onChange={(e) => {
                                updateData(e.target.value)
                            }}
                        ></Input>
                    </div>
        } else if (type === 'Boolean') {
            console.log('return select')
            return (
                <div className="d-flex flex-jbetween mt-2">
                    <span>Default Value</span>
                    <Select 
                            style={{ 'width': '300px' }}
                            defaultValue={false}
                            onChange={(value: any) => 
                                updateData(value)
                            }>
                        <Select.Option value={true}>True</Select.Option>
                        <Select.Option value={false}>False</Select.Option>
                    </Select>
                </div>
            );
        } else {
            return null;
        }
    }

    

    return (
        <div>
            {
                // _.isString(modalData.name) &&
                <div className="d-flex flex-jbetween mt-2">
                    <span>Name</span>
                    <Input style={{'width': '300px'}}
                           value={modalData.name}
                           onKeyDown={handleKeyPress}
                           onChange={(e) => {
                               let newData = {...modalData}
                               newData.name = e.target.value
                               setModalData(newData)
                               props.onUpdateModalData(newData)
                           }}
                    ></Input>
                </div>
            }
            {
                // _.isString(modalData.description) &&
                <div className="d-flex flex-jbetween mt-2">
                    <span>Description</span>
                    <TextArea style={{'width': '300px'}}
                           value={modalData.description}
                           rows={3}
                           onChange={(e) => {
                               let newData = {...modalData}
                               newData.description = e.target.value
                               setModalData(newData)
                               props.onUpdateModalData(newData)
                           }}
                    ></TextArea>
                </div>
            }
            {
                // _.isString(modalData.type) &&
                props.mode === ModeOption.SectionItem &&
                <div className="d-flex flex-jbetween mt-2">
                    <span>Type</span>
                    <Select
                        style={{width: '300px'}}
                        onChange={(e: any) => {
                            let newData = {...modalData}
                            newData.type = e
                            setModalData(newData)
                            props.onUpdateModalData(newData)
                        }}
                        defaultValue={modalData.type}
                        value={modalData.type}
                        options={ValueType}>

                    </Select>
                </div>
            }
            {
                // !_.isNil(modalData.defaultValue) &&
               // props.mode === ModeOption.SectionItem &&
                ValueInputByType()
                // <ValueInputByType></ValueInputByType>


                // <div className="d-flex flex-jbetween mt-2">
                //     <span>Default Value</span>
                //     <Input style={{ 'width': '300px' }}
                //            value={modalData.defaultValue}
                //            onChange={(e) => {
                //                let newData = { ...modalData }
                //                newData.defaultValue = e.target.value
                //                setModalData(newData)
                //                props.onUpdateModalData(newData)
                //            }}
                //     ></Input>
                // </div>
            }

        </div>
    )
}
