import {Button, Checkbox, Input, Select} from "antd";
import React, {useEffect, useState} from "react";
import {ModalData} from "../configType";


interface IProps {
    currentListInput: ModalData,
    onSetDeleteData: (deleteData: any) => void;
}

export const DeleteConfig = (props: IProps) => {
    const [currentList, setCurrentList] = useState<any>();

    useEffect( () => {
        setCurrentList(props.currentListInput)
    }, [])

    function onCheck(newValue: any) {
        let newList = {... currentList}
        newList.values = newList.values.map((val: any) => {
            val.selected = val.layerIndex === newValue.layerIndex;
            return val
        })
        setCurrentList(newList)
        props.onSetDeleteData(newList)
    }

    return (
        <div>
            <div>
                <span>Value:</span>
            </div>
            {
                currentList && currentList.values.map((val: any) => {
                    if (val.companyNumber) {
                        return (
                            <div className="mt-4 edit-grid" key={val.layerIndex}>
                                <span>{val.companyNumber} </span>
                                <div className="d-flex flex-jend flex-acenter">
                                    <span>{val.value}</span>
                                    <Checkbox className="ml-2"
                                              onChange={() => onCheck(val)}
                                              checked={val.selected}></Checkbox>
                                </div>
                            </div>
                        )}
                    }
                )
            }
        </div>

    )
}