import React, {useEffect, useState} from "react";
// import ReactJson from 'react-json-view'
import {Table, Typography, Select, Input, Space, Modal, Button, Checkbox, Tree, TreeProps, Switch} from "antd";
import {AppContext} from "../../context";
import {AddBranch} from "./ValueTable/AddBranch";

import type { ColumnsType } from 'antd/es/table';
import type { TableRowSelection } from 'antd/es/table/interface';
import {mockJsonDataArray} from "./mock-data";
const { Title } = Typography;
const { Option } = Select;
const { Search } = Input;


interface IProps {
    resource: any;
}

export const ConfigJsonEditor = ({ resource }: IProps) => {
    const { state, dispatch } = React.useContext(AppContext);
    const [data, setData] = useState<any>();

    useEffect(() => {
        setData({
            data: mockJsonDataArray
        })


    }, [])

    function onEdit(val: any) {
        console.log('val', val)
    }

    function onAdd(val: any) {
        console.log('val', val)
    }

    function onDelete(val: any) {
        console.log('val', val)
    }

    return (
        <div className="ss-app-body">
            <header className="App-header">
                <Title>
                    Configuration Metadata Json Editor
                </Title>
            </header>
            <div className="App-body">
                {
                    !!data &&
                    <div className="mt-4">
                        <div>
                            <span className="ml-4">Area:</span>

                            <Select className="ml-2"
                                    allowClear
                                    style={{ width: 150 }}>
                            </Select>
                        </div>
                        <div className="mt-4">
                            {/*<ReactJson src={data}*/}

                            {/*           enableClipboard={false}*/}
                            {/*           displayDataTypes={false}*/}
                            {/*           displayObjectSize={false}*/}
                            {/*           onEdit={onEdit}*/}
                            {/*           onAdd={onAdd}*/}
                            {/*           onDelete={onDelete}*/}
                            {/*           theme="monokai"*/}
                            {/*           style={{padding: '20px'}}*/}

                            {/*/>*/}
                        </div>

                    </div>
                }

            </div>
        </div>
    );
};
