import {Button, Checkbox, Divider, Input, Modal, Select} from "antd";
import React, {useEffect, useState} from "react";
import {getConfigRaw, setConfigItems} from "../../../remote";
import {AppContext, setConfigRaw} from "../../../context";
import {CreateConfig, CreateData} from "./CreateConfig";
import {DeleteConfig} from "./DeleteConfig";
import {EditConfig} from "./EditConfig";
import {ModalData} from "../configType";

interface IProps {
    modalModeInput: string;
    modalDataInput: any;
    modalOpenInput: boolean;
    closeModal: () => void;
}

export const ConfigPModal = (props: IProps) => {
    const { state, dispatch } = React.useContext(AppContext);

    const [modalMode, setModalMode] = useState('Edit');
    const [modalData, setModalData] = useState<ModalData>({} as ModalData)
    const [createData, setCreateData] = useState<CreateData | null>();
    const [deleteData, setDeleteData] = useState<any | null>();
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false)

    useEffect(() => {
        setModalData(props.modalDataInput)
        setIsModalOpen(props.modalOpenInput)
        return () => {
            setModalData({} as ModalData)
            setIsModalOpen(false)
        }
    }, [])

    useEffect(() => {
        setModalMode(props.modalModeInput)
        setModalData(props.modalDataInput)
        setIsModalOpen(props.modalOpenInput)
    }, [props.modalOpenInput])

    const handleOk = () => {
        if (modalMode === 'Add') {
            handleAdd()
            return;
        }

        if (modalMode === 'Delete') {
            handleDelete()
            return;
        }

        if (modalMode === 'Edit') {
            handleEdit()
            return;
        }

        throw('incorrect mode')
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        props.closeModal();
    };

    function handleAdd() {
        if (!createData) {
            throw 'Create data missing'
        }

        const breakKeys = modalData.name.split(':')
        let outputJson: any = {
            layers: {
                // selectedValue['layerIndex']: {"key": "global"},
            },
            configRoot: {nodes: {}}
        }

        // construct layers
        if (createData.company) {
            outputJson.layers[99] = {key: 'company-config', company: createData.company}
        } else {
            throw 'Company number is missing'
        }

        // build tree branches
        let outputTarget = formatOutputTargetFromNodeAndKeys(outputJson.configRoot.nodes, breakKeys)

        // set tree leaf with layer index
        outputTarget.values = {}
        outputTarget.values[99] = createData.value

        setConfigItems(outputJson)
            .then(() => {
                return getConfigRaw()
            })
            .then((configRaw) => {
                dispatch(setConfigRaw(configRaw))
            })
        setIsModalOpen(false);
        props.closeModal();
    }

    function handleEdit() {
        // find selected layer
        const selectedValues = modalData.values.filter((val: any) => {
            return val.selected === true;
        })
        if (!selectedValues || !selectedValues[0]) {
            throw 'Missing selected value on save.'
        }
        const selectedValue = selectedValues[0];

        const breakKeys = modalData.name.split(':')
        let outputJson: any = {
            layers: {
                // selectedValue['layerIndex']: {"key": "global"},
            },
            configRoot: {nodes: {}}
        }

        // construct layers
        if (selectedValue.companyNumber) {
            outputJson.layers[selectedValue.layerIndex] = {key: selectedValue.layerKey, company: selectedValue.companyNumber}
        } else {
            outputJson.layers[selectedValue.layerIndex] = {key: selectedValue.layerKey}
        }

        // build tree branches
        let outputTarget = formatOutputTargetFromNodeAndKeys(outputJson.configRoot.nodes, breakKeys)

        // set tree leaf with layer index
        outputTarget.values = {}
        outputTarget.values[selectedValue['layerIndex']] = selectedValue.value

        setConfigItems(outputJson)
            .then(() => {
                return getConfigRaw()
            })
            .then((configRaw) => {
                dispatch(setConfigRaw(configRaw))
            })
        setIsModalOpen(false);
        props.closeModal();
    }

    function handleDelete() {
        const selectedValues = deleteData.values.filter((val: any) => {
            return val.selected === true;
        })
        if (!selectedValues || !selectedValues[0]) {
            throw 'Missing selected value on save.'
        }
        const selectedValue = selectedValues[0];
        const breakKeys = modalData.name.split(':')
        let outputJson: any = {
            layers: {
                // selectedValue['layerIndex']: {"key": "global"},
            },
            configRoot: {nodes: {}}
        }

        // construct layers
        if (selectedValue.companyNumber) {
            outputJson.layers[selectedValue.layerIndex] = {key: selectedValue.layerKey, company: selectedValue.companyNumber}
        } else {
            throw 'Company number is missing'
        }

        // build tree branches
        let outputTarget = formatOutputTargetFromNodeAndKeys(outputJson.configRoot.nodes, breakKeys);
        outputTarget.overrideMarks = [selectedValue.layerIndex]
        setConfigItems(outputJson)
            .then(() => {
                return getConfigRaw()
            })
            .then((configRaw) => {
                dispatch(setConfigRaw(configRaw))
            })
        setIsModalOpen(false);
        props.closeModal();
    }

    function formatOutputTargetFromNodeAndKeys(nodes: any, keys: any) {
        let outputTarget = nodes
        //['Affinity', 'HealthCheck', 'InternalAspNet', 'BackgroundUpdateOffsetSeconds']
        let index = 1
        for(let branch of keys) {
            let branchNode
            if(keys.length > index) {
                branchNode = {nodes: {}}
                outputTarget[branch] = branchNode as any
                outputTarget = outputTarget[branch].nodes
            } else {
                branchNode = {}
                outputTarget[branch] = branchNode as any
                outputTarget = outputTarget[branch]
            }

            index++
        }
        return outputTarget
    }

    function afterClose() {
        resetModal();
    }

    function resetModal() {
        setModalMode('Edit')
        setModalData({} as ModalData)
        setCreateData(null)
    }

    return (
        <Modal title={modalMode} open={isModalOpen}
           onOk={handleOk} okText="Save"
           onCancel={handleCancel}
           afterClose={afterClose}>
            {/* <div className="d-flex flex-jbetween">
                <span>
                    <span>Mode:</span>
                    <span className="ml-2">
                    {
                        (modalMode === 'Edit' && <span>Edit</span>)
                        || (modalMode === 'Add' && <span>Add</span>)
                        || (modalMode === 'Delete' && <span>Delete</span>)
                    }
                    </span>
                </span>
                <span>
                    {
                        modalMode !== 'Add' && <Button size="small" onClick={() => setModalMode('Add')}>Add</Button>
                    }
                    {
                        modalMode !== 'Edit' && <Button size="small" onClick={() => setModalMode('Edit')} className="ml-2">Edit</Button>
                    }
                    {
                        modalMode !== 'Delete' &&  <Button size="small" onClick={() => setModalMode('Delete')} className="ml-2">Delete</Button>
                    }

                </span>

            </div>
            <Divider></Divider> */}

            <div className="d-flex flex-jbetween mt-2">
                <span className="modal-label-inline">Technical Name: </span>
                <span className="word-break-word">{modalData.name}</span>
            </div>

            {
                (modalMode === 'Edit'
                    && <EditConfig modalDataInput={modalData} onSetEditData={setModalData}></EditConfig>)
            }
            {
                (modalMode === 'Add'
                    && <CreateConfig modalDataInput={modalData} onSetCreateData={setCreateData}></CreateConfig>)
            }
            {
                (modalMode === 'Delete')
                && <DeleteConfig onSetDeleteData={setDeleteData} currentListInput={modalData}></DeleteConfig>
            }

    </Modal>
   )
}