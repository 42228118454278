import {Button, Checkbox, Input, Select} from "antd";
import React, {useEffect, useState} from "react";
import {EditableField} from "../configType";


interface IProps {
    modalDataInput: any;
    onSetEditData: (modalData: any) => void;
}

export const EditConfig = (props: IProps) => {
    const [modalData, setModalData] = useState<any>({})

    useEffect(() => {
        setModalData(props.modalDataInput)
    }, [props.modalDataInput])

    const handleSelectBool = (value: boolean, layerIndex: number) => {
        const newData = {...modalData};
        newData.values = newData.values.map((dataVal: EditableField) => {
            let newDataVal = {...dataVal} // copy object again because the above newData is shallow copy
            if (newDataVal.layerIndex === layerIndex) {
                newDataVal.value = value
            }
            return newDataVal
        })
        setModalData(newData)
        props.onSetEditData(modalData)
    }

    function onSetStringValue(value: string, layerIndex: number) {
        const newData = {...modalData};
        newData.values = newData.values.map((dataVal: EditableField) => {
            let newDataVal = {...dataVal} // copy object again because the above newData is shallow copy
            if (newDataVal.layerIndex === layerIndex) {
                newDataVal.value = value
            }
            return newDataVal
        })
        // todo set modal value
        setModalData(newData)
        props.onSetEditData(newData)
    }

    const onCheck = (val: any) => {
        const newData = {... modalData};
        newData.values = newData.values.map((dataVal: EditableField) => {
            let newVal = {...dataVal}
            if (newVal.layerIndex === val.layerIndex) {
                newVal.selected = !newVal.selected
            } else {
                newVal.selected = false;
            }
            return newVal
        })
        setModalData(newData)
        props.onSetEditData(newData)
    }

    return (
        <div>
            <div>
                <span>Value:</span>
            </div>
            {
                modalData.values && modalData.values.map((val: any) => {
                    return (
                        <div className="mt-4 edit-grid" key={val.layerIndex}>
                            <span>{val.companyNumber ? val.companyNumber : val.layerKey} </span>
                            <div className="d-flex flex-jend flex-acenter">
                                {
                                    (modalData.type === 'String'
                                        || modalData.type === "Integer"
                                        || modalData.type === "Double"
                                        || modalData.type === "Uri"
                                        || modalData.type === "Guid")
                                    &&
                                    <Input style={{width: 300}}
                                           defaultValue={val.value}
                                           disabled={!val.selected}
                                           onChange={(e: any) => onSetStringValue(e.target.value, val.layerIndex)}
                                           value={val.modalValue}/>
                                }
                                {
                                    modalData.type === 'Boolean' &&
                                    <Select
                                        style={{width: 120}}
                                        onChange={(e: any) => handleSelectBool(e, val.layerIndex)}
                                        defaultValue={val.value}
                                        options={[
                                            {
                                                value: true,
                                                label: 'True'
                                            }, {
                                                value: false,
                                                label: 'False'
                                            }
                                        ]}>

                                    </Select>
                                }
                                <Checkbox className="ml-2"
                                          onChange={() => onCheck(val)}
                                          checked={val.selected}></Checkbox>
                            </div>

                        </div>
                    )}
                )
            }
        </div>

    )
}