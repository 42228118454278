import React, {Suspense, useEffect, useState} from "react";
import {Table, Typography, Select, Input, Space, Modal, Button, Checkbox, Tree, TreeProps, Switch, notification} from "antd";
import {AppContext} from "../../../context";
import {AddBranch} from "../ValueTable/AddBranch";
import _, { each } from 'lodash';

import type { ColumnsType } from 'antd/es/table';
import type { TableRowSelection } from 'antd/es/table/interface';

import {mockJsonDataArray, mockJsonGPTRaw, mockJsonTableRaw} from "../mock-data";
import {EditMetaDataValueModal, EditModalMode} from "./EditMetaDataValueModal";

import {
    DataType,
    findNodeByKey,
    formatTreeNodeForBackend,
    generateTreeNode,
    generateTreeNodeV2,
    insertBranch,
    insertLeaf,
    leafBranchValidate,
    rearrangeSourceIntoRoot,
    removeBranch,
    resetKeyInTree,
    resetKeyInTreeArray,
    TreeNode
} from "./format";
import Icon, {CloseOutlined, CloudUploadOutlined, DeleteOutlined, FrownOutlined, PlusOutlined, SmileOutlined, createFromIconfontCN} from "@ant-design/icons";
import { getAreaContent, getConfigMetadataAreas, postAreaContent } from "../../../remote";
import { diff, addedDiff, deletedDiff, updatedDiff, detailedDiff } from 'deep-object-diff';
import { green, red } from '@ant-design/colors';
import { AppLoader } from "../../AppLoader";
import { Link } from "react-router-dom";
//import { TreeTable } from "./TreeTable";
import { ReactComponent as Pencil } from "../../../assets/ico/pencil.svg";
import { greenButtonStyle } from "../../../utils/button";
import { DiskIco } from "../../../assets/ico";

const { Title } = Typography;
const { Option } = Select;
const { Search } = Input;
const { Column } = Table;


interface IProps {
    resource: any;
}

export const ConfigPortalTree = ({ resource }: IProps) => {
    const { state, dispatch } = React.useContext(AppContext);
    const [remoteTreeData, setRemoteTreeData] = useState<any>();
    const [originTreeData, setOriginTreeData] = useState<any>();
    const [treeData, setTreeData] = useState<any>();
    const [areas, setAreas] = useState<any>();
    const [selectedArea, setSelectedArea] = useState<any>();
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [editModalMode, setEditModalMode] = useState<EditModalMode >(EditModalMode.Edit);
    const [tableLoading, setTableLoading] = useState<boolean>(true);
    const [expandedRowKeys, setExpandRowKeys] = useState<any[]>([]);
    const [modalData, setModalData] = useState<any>({})
    const [modal, contextHolder] = Modal.useModal();
    const columns: ColumnsType<DataType> = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            width: '450px',
            filterSearch: true,
            render: (text: string, record) => {
                return(
                    <span>
                        <span>{text}</span>
                        {
                            !!record.changed &&
                            <span className="ml-2"><CloudUploadOutlined style={{ color: '#ff6700' }} /></span>
                        }

                    </span>)
            },

        },
       
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Data Type',
            dataIndex: 'type',
            width: '120px',
            key: 'type',
        },
        {
            title: 'Default Value',
            dataIndex: 'defaultValue',
            width: '20%',
            key: 'defaultValue',
        },
        {
            title: 'Area',
            dataIndex: 'area',
            width: '140px',
            key: 'area',
        },
        {
            title: '',
            key: 'action',
            render: (_, record) => {
                if (record.name && record.name !== '') {
                    return (<Space size="middle">
                            
                            <Button className="config-btn lightblue-btn"
                                    onClick={() => openEditModal(record, EditModalMode.Edit)}
                                    icon={<Icon component={Pencil} />}>Edit</Button>

                            {
                                (record.children && record.children.length > 0
                                    || (!record.defaultValue && !record.type)) &&
                                <Button className="config-btn green-btn"
                                    style={{ height: '40px', width: '60px' }}
                                    onClick={() => openEditModal(record, EditModalMode.Add)}
                                    icon={<PlusOutlined />}></Button>
                            }       
                            
                            {
                                (!record.children || record.children.length === 0) &&
                                <Button className="config-btn red-btn"
                                    style={{ height: '40px', width: '60px' }}
                                    onClick={() => deleteBranch(record)}
                                    icon={<DeleteOutlined />}></Button>
                            }
                    </Space>)
                } else {
                    return null
                }

            },
            width: "240px"
        },
    ];

    useEffect(() => {
        initLoadRemoteData();
        // getConfigMetadataAreas().then(res => {
        //     let rawArea = res.data;
        //     let areas = rawArea.map((area: any) => {
        //         return  { value: area.id, label: area.id }
        //     })
        //     console.log('area', res.data)
            
        //     setAreas(areas)
        //     return res;
        // })
        
        // // let mockkeys = mockJsonTableRaw.keys();
        // // const source = mockJsonTableRaw[0].items
        // let source
        // getAreaContent()
        // .then(res => {
        //     // console.log('res', res.data[2])
            
           
        // })
        // const mockkeys = Object.keys(mockJsonTableRaw[0].items);
        // const tree = { key: 1, name: '', children: [] };
        // mockJsonTableRaw[0].items.map(item => {

    }, [])

    async function initLoadRemoteData() {
        setTableLoading(true)
        const remoteMetaDataArea = await getConfigMetadataAreas()
        let rawArea = remoteMetaDataArea.data;
        let areas = rawArea.map((area: any) => {
            return  { value: area.id, label: area.id }
        })
        areas.unshift({ value: 'all', label: 'All' })
        
        setSelectedArea(areas[0].value)
        const selectedArea = areas[0].value
        
        setAreas(areas)

        // todo set first load area
        await loadTableDataRemote(selectedArea)
        setTableLoading(false)
    }

    async function loadTableDataRemote (instantSelectedArea?: string) {
        const remoteAreaContent = await getAreaContent();
        // const remoteAreaContent = mockJsonGPTRaw
        // loadDataIntoTree(remoteAreaContent)
        if (remoteAreaContent) {  
            let currentArea = selectedArea ? selectedArea : instantSelectedArea
            // const foundData = remoteAreaContent.data.find((rTree: any) => {
            // const foundData = remoteAreaContent.data.find((rTree: any) => {
            //     return rTree.id === currentArea
            // })
            // let source 
            // if (foundData) {
            //     source = foundData.items
            // } else {
            //     source = remoteAreaContent.data[0].items
            // }
            let source = remoteAreaContent.data

            // const source = remoteAreaContent.data[2].items
            setRemoteTreeData(remoteAreaContent.data)
            loadDataIntoTree(source)
        }   
    }

    function loadDataIntoTree(source: any) {
        let rootNodeArr: any[] = []
        let nodeArr; 
        let counter = 1;
        for (let rootItem of source) {
            nodeArr = rearrangeSourceIntoRoot(rootItem.items, rootItem.id)
            
            // let newRoot = {
            //     key: 'root' + counter,
            //     name: rootItem.id,
            //     children: nodeArr
            // }
            rootNodeArr = [...rootNodeArr, ...nodeArr]
            counter++
        }
        // let rootNodeArr = rearrangeSourceIntoRoot(source)

        // todo: what about v2?
        // generateTreeNodeV2(source)
        console.log('rootNodeArr', rootNodeArr)
        resetKeyInTreeArray(rootNodeArr, 0 )
        
        setTreeData(rootNodeArr)
        setOriginTreeData(rootNodeArr)
    
        setTableLoading(false)
    }

    function filterTreeByName(tree: TreeNode, filterValue: string): any{
        if (tree.name.toLowerCase() === filterValue.toLowerCase()) {
            return tree;
        }
        
        const newChildren = (tree.children || [])
                                .map((child) => filterTreeByName(child, filterValue))
                                .filter((child) => !!child);
        
        if (newChildren.length === 0) {
            return null;
        }
        
        return {
            ... tree,
            children: newChildren,
        };
    }

    function searchTree(searchText: string): void {
        if (searchText) {
            const filteredArr = filterTree(originTreeData, searchText)
            setTreeData(filteredArr)
        } else {
            setTreeData(originTreeData)
        }
    }

    function filterTree(trees: TreeNode[], searchText: string): any {
        return trees.map((tree: TreeNode) => {
            let newChildren: TreeNode[] = [];

            if (tree.name.toLowerCase().includes(searchText.toLowerCase())) {
                return tree
            }

            if (tree.children) {
                newChildren = filterTree(tree.children, searchText) ?? [] ;
            }
            if (newChildren.length > 0) {
                return {
                    ... tree,
                    children: newChildren
                };
            } else {
                return null
            }
        }).filter(Boolean)
    }

    function changeArea(area: any): void {
        setSelectedArea(area)
        const foundData = remoteTreeData.find((rTree: any) => {
            return rTree.id === area
        })
        if (foundData) {
            let source  = [foundData]
            loadDataIntoTree(source)
        } else {
            loadDataIntoTree(remoteTreeData)
        }
        
        // throw new Error("Function not implemented.");
    }

    // function showEditModal(record: any) {
    //     // record.description = '111'
    //     setModalData(record);
    //     setIsModalOpen(true)
    // }

    function openEditModal(record: DataType, mode: EditModalMode) {
        setEditModalMode(mode)
        setModalData(record)
        setIsModalOpen(true)
    }

    function onCloseEditModal(modalDataChanged: any) {
        console.log('modalData', modalData)
        setIsModalOpen(false)       
        if (!modalDataChanged) return;

        if (editModalMode === EditModalMode.Add) {
            if (!_.isNil(modalDataChanged.defaultValue)) {
                addNewLeafBelow(modalData, modalDataChanged)
            } else {
                addNewBranchBelow(modalData, modalDataChanged)
            }
            return;
        }

        // Edit
        let treeDataClone = _.cloneDeep(treeData)
        let targetObj = findDataFromTree(treeDataClone, modalDataChanged.key)
        modalDataChanged.name ? targetObj.name = modalDataChanged.name : targetObj.name = null;
        modalDataChanged.description ? targetObj.description = modalDataChanged.description : targetObj.description = null;
        modalDataChanged.type ? targetObj.type = modalDataChanged.type : targetObj.type = null;
        modalDataChanged.tag ? targetObj.tag = modalDataChanged.tag : targetObj.tag = null;
        !_.isNil(modalDataChanged.defaultValue) ? targetObj.defaultValue = modalDataChanged.defaultValue : targetObj.defaultValue = null;
        modalDataChanged.preferStoreAsJson ? targetObj.preferStoreAsJson = modalDataChanged.preferStoreAsJson : targetObj.preferStoreAsJson = null;
        modalDataChanged.allowNull ? targetObj.allowNull = modalDataChanged.allowNull : targetObj.allowNull = null;
        targetObj.changed = true;
        setTreeData(treeDataClone)
    }

    function findDataFromTree(treeData: any, key: number) {
        const keyArray = key.toString().split('')
        let treeObject = treeData
        keyArray.map((keyIndex, index: number) => {
            if (index === 0) {
                treeObject = treeData[Number(keyIndex) - 1]
            } else {
                treeObject = treeObject.children[Number(keyIndex) - 1]
            }
        })
        return treeObject
    }

    async function save() {
        // show loading lialog

        let mergedTreeDataArr: any[] = []
        treeData.map((tree: any) => {
            let foundArr = mergedTreeDataArr.find(item => item[0].area === tree.area) 
            if (!foundArr) {
                mergedTreeDataArr.push([tree])
            } else {
                foundArr.push(tree)
            }
        })

        const allFinalArr = mergedTreeDataArr.map((eachTreeData: any) => {
            const finalArr = formatTreeNodeForBackend(eachTreeData, {})
            const etag = getEtagbyArea(eachTreeData[0].area)
            return {
                area: eachTreeData[0].area,
                finalArr,
                etag
            }
        })
        if (selectedArea === 'all') {
            const PromisQueue: any = []
            for (let obj of allFinalArr){
                obj.finalArr.shift()
                let finalJson: any = {}

                obj.finalArr.map((item: any) => {
                    let itemKey = Object.keys(item)[0];
                    finalJson[itemKey] = item[itemKey]
                })
                let cleanJson = _.pickBy(finalJson, (j) => !_.isEmpty(j));

                await (sendSaveRequest(cleanJson, obj.area, obj.etag))
            }
        } else {
            const foundData = allFinalArr.find((rObj: any) => {
                return rObj.area === selectedArea
            })
            if (!foundData) throw 'did not find selected Area'
            foundData.finalArr.shift()
            let finalJson: any = {}
            foundData.finalArr.map((item: any) => {
                let itemKey = Object.keys(item)[0];
                finalJson[itemKey] = item[itemKey]
            })

            const foundDataOrigin = remoteTreeData.find((rTree: any) => {
                return rTree.id === selectedArea
            })
            if (!foundData) throw 'did not find selected Area'

            let diffresult = detailedDiff( finalJson, foundDataOrigin.finalArr)
            console.log('diff result', diffresult)
            let cleanJson = _.pickBy(finalJson, (j) => !_.isEmpty(j));

            sendSaveRequest(cleanJson, foundData.area, foundData.etag)
        }
    }

    async function sendSaveRequest(cleanJson: any, area: any, etag: any) {
        const data = [{
            id: area,
            items: cleanJson,
            etag: etag
        }]

        try {
            const saveResult = await postAreaContent(data)

            notification.open({
                message: 'Save Successed',
                description:
                    'Save metadata change has been successed',
                icon: <SmileOutlined style={{ color: green[6] }} />,
            });
            // todo: should I clear table?
            setTableLoading(true)
            await loadTableDataRemote()
            setTableLoading(false)

        } catch(e) {
            notification.open({
                message: 'Save Failed',
                description:
                  'Save metadata change failed',
                icon: <FrownOutlined style={{ color: red[6] }} />,
            });
        }
    }

    function getEtagbyArea(area: string) {
        const foundData = remoteTreeData.find((rTree: any) => {
            return rTree.id === area
        })
        if (!foundData) throw 'did not find selected Area'
        return foundData.etag
    }
    
    function addNewBranchBelow(record: DataType, newBranchObj: DataType) {
        // todo: only do this when close add modal
        console.log('record', record)
        setTreeData(insertBranch(_.cloneDeep(treeData), record, newBranchObj))
    }

    function addNewLeafBelow(record: DataType, newLeafObj: DataType) {
        setTreeData(insertLeaf(_.cloneDeep(treeData), record, newLeafObj))
    }

    function onExpanded(expanded: boolean, record: any) {
        let newKeys = expandedRowKeys.slice()
        if (expanded) {
            newKeys.push(record.key);
        } else {
            let index = newKeys.indexOf(record.key);
            if (index !== -1) {
                newKeys.splice(index, 1);
            }
        }
        setExpandRowKeys(newKeys)
    }

    function deleteBranch(record: DataType) {
        modal.confirm({
            title: 'Are you sure you want to delete this item?',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            
            onOk() {
                // Handle delete action
                let removedBranchRecord = removeBranch(_.cloneDeep(treeData), record)
                let node = findNodeByKey(record.key, removedBranchRecord)
                if (node.children && node.children.length > 0) {
                    //
                } else {
                    node.children = null;
                    // remove key from expanded row
                    const index = expandedRowKeys.indexOf(node.key);
                    expandedRowKeys.splice(index, 1);
                    setExpandRowKeys(expandedRowKeys)
                }
                setTreeData(removedBranchRecord)
            },
            onCancel() {
                // Do nothing
            },
        });
    }

    
    return (
        <div className="ss-app-body">
            <header className="flex flex-jstart ml-4">
                <span className="page-title">
                    Metadata Editing
                </span>
            </header>
            <div className="App-body" style={{paddingBottom: 40}}>
                <div className="d-flex flex-jbetween flex-acenter">
                    <div className="mt-4 d-flex flex-acenter">
                        <span>Search:</span>
                        <Search className="ml-2" style={{ width: 200 }}
                                placeholder={'Type to search.'}
                                onSearch={(val: string) => searchTree(val)}
                                allowClear
                        />
                        {
                            areas &&

                            <span>
                                <span className="ml-4">Area:</span>
                                <Select
                                    className="ml-2"
                                    defaultValue={selectedArea ? selectedArea : areas[1].value}
                                    style={{ width: 300 }}
                                    options={areas}
                                    onChange={(area) => changeArea(area)}
                                />
                            </span>
                            
                        }
                        
                    </div>
                    <div className="d-flex flex-acenter">
                        <div>
                            <Button className="config-btn green-btn" icon={<DiskIco />} onClick={() => save()}>
                                <span className="ml-2">Save</span>
                            </Button>
                        </div>
                    </div>
                    
                </div>
                {
                    <div className="mt-4">
                        <Table
                            columns={columns}
                            loading={tableLoading}
                            expandable={{
                                expandedRowKeys: expandedRowKeys,
                                onExpand: (expanded, record) => onExpanded(expanded, record)}
                            }
                            pagination={false}
                            dataSource={treeData}>
                        </Table>
                    </div>
                }
            </div>
            <EditMetaDataValueModal
                modalDataInput={modalData}
                modalOpenInput={isModalOpen}
                modalMode={editModalMode}
                closeModal={(modalDataChanged) => onCloseEditModal(modalDataChanged)}
                handleDelete={ (record) =>deleteBranch(record)}
               //  handleAddSection={(record) => addNewBranchBelow(record)}
            ></EditMetaDataValueModal>
            <div>{contextHolder}</div>
        </div>
    );
};
