import {Button, Divider, Modal, Select, notification} from "antd";
import React, {useEffect, useState} from "react";
import _ from "lodash";
import {EditMetaData} from "./EditMetaData";
import { leafBranchValidate, rowType } from "./format";


export enum EditModalMode {
    Add = 'Add',
    Edit = 'Edit'
}

interface IProps {
    modalDataInput: any;
    modalOpenInput: boolean;
    modalMode: EditModalMode
    handleDelete: (record: any) => void;
    // handleAddSection: (record: any) => void;
    closeModal: (modalData?: any) => void;
}

export enum ModeOption {
    Section = 'Section',
    SectionItem = 'Section Item'
}

const fields = [
    { name: 'name', label: 'Name'},
    { name: 'description', label: 'Description' },
    { name: 'type', label: 'Type', leafOnly: true },
    { name: 'tags', label: 'Tags' },
    { name: 'defaultValue', label: 'Default Value', leafOnly: true},
];

export const EditMetaDataValueModal = (props: IProps) => {
    const [modalData, setModalData] = useState<any>({});
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    // const [isBranch, setBranch] = useState<boolean>(false)
    const [mode, setMode] = useState<ModeOption>(ModeOption.Section)

    useEffect(() => {
        setIsModalOpen(props.modalOpenInput);
    }, []);

    useEffect(() => {
        if (props.modalMode ===  EditModalMode.Add) {
            setModalData({});
            setMode(ModeOption.Section)
            setIsModalOpen(props.modalOpenInput);
            return
        }


        if (props.modalDataInput) {
            setModalData(props.modalDataInput);
            if ((props.modalDataInput.children && props.modalDataInput.children.length > 0) 
                && _.isNil(props.modalDataInput.defaultValue)) {
                setMode(ModeOption.Section)
                // setBranch(true)
            } else {
                setMode(ModeOption.SectionItem)
                // setBranch(false)
            }
            setIsModalOpen(props.modalOpenInput);
        }

        console.log('props.modalOpenInput', props.modalOpenInput)
    }, [props.modalOpenInput]);

    const handleOk = () => {
        if (modalData.name === '' || !modalData.name) {
            notification.error({
                message: 'Name field is required',
                placement: 'top',
                top: 50,
                duration: 3,
            });
            // throw 'name is required'
            return;
        }

        if (leafBranchValidate(modalData) === rowType.leaf) {
            if (modalData.defaultValue === '' || !modalData.defaultValue) {
                notification.error({
                    message: 'Default Value field is required',
                    placement: 'top',
                    top: 50,
                    duration: 3,
                });
                return;
            }
        }


        if (leafBranchValidate(modalData) === rowType.leaf || leafBranchValidate(modalData) === rowType.branch) {
            props.closeModal(modalData);
        } else {
            throw 'mixing leaf and branch type'
        }  
    };

    // const handleAddSection = () => {
    //     console.log('props.modalDataInput', props.modalDataInput)
    //     props.handleAddSection(modalData)

    //     props.closeModal();
    // }

    const handleDelete = () => {
        props.handleDelete(modalData)
        props.closeModal();
    }

    const handleCancel = () => {
        setIsModalOpen(false);
        props.closeModal();
    };

    const handleFieldChange = (name: string, isAdd: boolean) => {
        const clonedModalData = _.cloneDeep(modalData);

        if (isAdd) {
            if (name === 'description'
                || name === 'type'
                || name === 'defaultValue') {
                clonedModalData[name] = '';
            } else if (name === 'tags') {
                clonedModalData[name] = [];
            }
        } else {
            if (name === 'defaultValue') {

            }
            clonedModalData[name] = null;
        }

        setModalData(clonedModalData);
    };

    const selectOption = (option: string ) => {
        console.log('option', option)
        
        setMode(option as ModeOption)
        // if (option === 'section') {
        //     setMode(ModeOption.Section)
        // } else if (option === 'sectionItem') {
        //     setMode(ModeOption.SectionItem)
        // }
    }

    const getTitle = () => {
        if (props.modalMode === EditModalMode.Add) {
            return 'Add New'
        }

        if (mode === ModeOption.Section) {
            return 'Edit Section'
        } else {
            return 'Edit Section Item'
        }
    }

    return (
        <Modal title={getTitle()}
               open={isModalOpen}
               onCancel={handleCancel}
               footer={
                    <div className="d-flex flex-jend">
                        {/* <div>
                            <Button onClick={handleAddSection}>Add Section</Button>
                            <Button danger onClick={handleDelete}>Delete</Button>
                        </div> */}
                        <div style={{ textAlign: 'center' }}>
                            <Button onClick={handleOk}>Save</Button>
                            <Button onClick={handleCancel}>Cancel</Button>
                        </div>
                    </div>
                    
               }>
            <div>
                {
                    (props.modalMode === EditModalMode.Add) && 
                    <div className="d-flex flex-jbetween">       
                        <span>type:</span>    
                        <Select  
                            defaultValue={ModeOption.Section}
                            onSelect={((option) => selectOption(option))}
                            value={mode}
                            options={[
                                {
                                    value: ModeOption.Section,
                                    label: 'Section'
                                }, {
                                    value: ModeOption.SectionItem,
                                    label: 'Section Item'
                                }]
                            }
                            style={{ width: 150 }}>
                        </Select>        
                    </div>
                }
                    
                <EditMetaData 
                    modalDataInput={modalData}
                    mode={mode}
                    onUpdateModalData={(modalData: any) => setModalData(modalData)}
                ></EditMetaData>
            </div>
        </Modal>
    );
    
};
