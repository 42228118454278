export const bkData = {
    "configRoot": {
        "nodes": {
            "ConfigEngineTesting": {
                "nodes": {
                    "FrontendTest1": {
                        "nodes": {
                            "SimpleString": {
                                "values": {
                                    "1": "test add",
                                }
                            }
                        }
                    },
                }
            }
        }
    },
    'layers': {
        nodes: {
            1: {key: 'global'}
        }
    }
}

export const mockJsonTableRaw: any = [
    {
        id: '1',
        items:
            {
                "Affinity": {
                    "description": "The Affinity Products configuration root"
                },
                "Affinity:AppCommon": {
                    "description": "Common settings for all applications",
                    "tags": [
                        "Infrastructure",
                        "Category:System"
                    ]
                },
                "Affinity:AppCommon:TenantId": {
                    "description": "The Guid ID of the Azure AD Main Tenant Id",
                    "tags": [
                        "EnvParams"
                    ],
                    "valueMetadata": {
                        "valueType": "Guid"
                    }
                },
                "Affinity:AppCommon:AppCommonSpClientId": {
                    "description": "The Client ID (App  ID) of the sp_affinity_appcommon_{EnvInstance} service principal.",
                    "valueMetadata": {
                        "valueType": "Guid"
                    }
                },
                "Affinity:AppCommon:EnvInstance": {
                    "description": "The name of the 'Environment Instance'",
                    "valueMetadata": {
                        "valueType": "String"
                    }
                },
                "Affinity:AppCommon:RootDomain": {
                    "description": "The root domain where all great applications are published",
                    "tags": [
                        "EnvParams"
                    ],
                    "valueMetadata": {
                        "valueType": "String"
                    }
                },
                "Affinity:AppCommon:KeyVaultUri": {
                    "description": "The Url of the KeyVault that is used by AppCommon infrastructure to other libs",
                    "valueMetadata": {
                        "valueType": "Uri"
                    }
                },
                "Affinity:AppCommon:BlobServiceUri": {
                    "description": "The Url of the Azure BlobService that is provided by the AppCommon infrastructure to other libs",
                    "valueMetadata": {
                        "valueType": "Uri"
                    }
                },
                "Affinity:Endpoints": {
                    "description": "The home grown affinity service discovery static registry",
                    "tags": [
                        "Category:System",
                        "ServiceDiscovery"
                    ]
                },
                "Affinity:Endpoints:@{}": {
                    "description": "The name of the entry in the service discovery static registry",
                    "preferStoreAsJson": true
                },
                "Affinity:Endpoints:@{}:Endpoint": {
                    "description": "The Virtual URL that should be matached to use this entry for connectivity",
                    "valueMetadata": {
                        "valueType": "Uri"
                    }
                },
                "Affinity:Endpoints:@{}:ResolvedEndpoints": {
                    "description": "The list of real URL that this entry should be resolved to. Use empty element to finish list if it's a override layer."
                },
                "Affinity:Endpoints:@{}:ResolvedEndpoints:DaprAppId": {
                    "description": "The DAPR application identifier to put into headers when DAPR proxy is used to connect to the service."
                },
                "Affinity:Endpoints:@{}:ResolvedEndpoints:@[]": {
                    "description": "The real URL, that can be resolved by the application network subsystem.",
                    "valueMetadata": {
                        "valueType": "Uri"
                    }
                },
                "Affinity:Endpoints:@{}:HealthCheckProviders": {
                    "description": "The list of providers that ensures that the resolved URL is healthy."
                },
                "Affinity:Endpoints:@{}:HealthCheckProviders:@[]": {
                    "description": "Internal provider name. Supported: 'internall-http-aspnet' ",
                    "valueMetadata": {
                        // It could be enum!
                        "valueType": "String"
                    }
                },
                "Affinity:Identity:B2CTenantName": {
                    "description": "The name of the B2C Tenant that is used in the URL/Token strings.",
                    "valueMetadata": {
                        "valueType": "String"
                    }
                },
                "Affinity:Identity:B2CTenantId": {
                    "description": "The ID of the B2C Tenant that is used in the URL/Token strings.",
                    "valueMetadata": {
                        "valueType": "Guid"
                    }
                },
                "Affinity:HealthCheck:InternalAspNet": {
                    "description": "The options for the affinity asp.net health check provider.",
                    "preferStoreAsJson": true
                },
                "Affinity:HealthCheck:InternalAspNet:BackgroundUpdateOffsetSeconds": {
                    "description": "The time between early background health check and last status expiration. When this value is zero, the background check will not be initiated.",
                    "valueMetadata": {
                        "valueType": "Integer",
                        "defaultValue": 0
                    }
                },
                "Affinity:HealthCheck:InternalAspNet:HealthyStatusExpirationTimeoutSeconds": {
                    "description": "The timeout before next health check if the current status is healthy.",
                    "valueMetadata": {
                        "valueType": "Integer"
                    }
                },
                "Affinity:HealthCheck:InternalAspNet:UnhealthyStatusCacheTimeoutSeconds": {
                    "description": "The timeout before next health check if the current status is unhealthy.",
                    "valueMetadata": {
                        "valueType": "Integer"
                    }
                },
                "Affinity:HealthCheck:InternalAspNet:CheckTimeoutSeconds": {
                    "description": "The timeout for the check request itself.",
                    "valueMetadata": {
                        "valueType": "Integer"
                    }
                },
                "Affinity:HealthCheck:InternalAspNet:HealthCheckApiPath": {
                    "description": "The relative Uri for the endpoint which provides health status.",
                    "valueMetadata": {
                        "valueType": "Uri",
                        "defaultValue": "/"
                    }
                }
            },
        etag: '1234'
    },
    {"id":"appcommon","items":{
"Affinity2:AppCommon":{"description":"Common settings for all applications","tags":["Infrastructure","Category:System"],"preferStoreAsJson":false},
"Affinity2:AppCommon:TenantId":{"description":"The Guid ID of the Azure AD Main Tenant Id","tags":["EnvParams"],"valueMetadata":{"allowNull":false,"valueType":"Guid"},"preferStoreAsJson":false},
"Affinity2:AppCommon:AppCommonSpClientId":{"description":"The Client ID (App ID) of the sp_affinity_appcommon_{EnvInstance} service principal.","valueMetadata":{"allowNull":false,"valueType":"Guid"},"preferStoreAsJson":false},
"Affinity2:AppCommon:EnvInstance":{"description":"The name of the 'Environment Instance'","valueMetadata":{"allowNull":false,"valueType":"String"},"preferStoreAsJson":false},
"Affinity2:AppCommon:RootDomain":{"description":"The root domain where all great applications are published","tags":["EnvParams"],"valueMetadata":{"allowNull":false,"valueType":"String"},"preferStoreAsJson":false},
"Affinity2:AppCommon:KeyVaultUri":{"description":"The Url of the KeyVault that is used by AppCommon infrastructure to other libs","valueMetadata":{"allowNull":false,"valueType":"Uri"},"preferStoreAsJson":false},
"Affinity2:AppCommon:BlobServiceUri":{"description":"The Url of the Azure BlobService that is provided by the AppCommon infrastructure to other libs","valueMetadata":{"allowNull":false,"valueType":"Uri"},"preferStoreAsJson":false},
"Affinity2:Endpoints":{"description":"The home grown affinity service discovery static registry","tags":["Category:System","ServiceDiscovery"],"preferStoreAsJson":false},
"Affinity2:Endpoints:@{}":{"description":"The name of the entry in the service discovery static registry","preferStoreAsJson":true},
"Affinity2:Endpoints:@{}:Endpoint":{"description":"The Virtual URL that should be matached to use this entry for connectivity","valueMetadata":{"allowNull":false,"valueType":"Uri"},"preferStoreAsJson":false},
"Affinity2:Endpoints:@{}:ResolvedEndpoints":{"description":"The list of real URL that this entry should be resolved to. Use empty element to finish list if it's a override layer.","preferStoreAsJson":false},
    },  etag: '4567'},
    {"id":"appcommon2","items":
    {"Affinity3":{"description":"The Affinity Products configuration root","preferStoreAsJson":false},
    "Affinity3:AppCommon":{"description":"Common settings for all applications","tags":["Infrastructure","Category:System"],"preferStoreAsJson":false},
    "Affinity3:AppCommon:TenantId":{"description":"The Guid ID of the Azure AD Main Tenant Id","tags":["EnvParams"],"valueMetadata":{"allowNull":false,"valueType":"Guid"},"preferStoreAsJson":false},
    "Affinity3:AppCommon:AppCommonSpClientId":{"description":"The Client ID (App ID) of the sp_affinity_appcommon_{EnvInstance} service principal.","valueMetadata":{"allowNull":false,"valueType":"Guid"},"preferStoreAsJson":false},
    "Affinity3:AppCommon:EnvInstance":{"description":"The name of the 'Environment Instance'","valueMetadata":{"allowNull":false,"valueType":"String"},"preferStoreAsJson":false},
    "Affinity3:AppCommon:RootDomain":{"description":"The root domain where all great applications are published","tags":["EnvParams"],"valueMetadata":{"allowNull":false,"valueType":"String"},"preferStoreAsJson":false},
    },"etag":"\"2f00e620-0000-1a00-0000-642a16ae00002\""}
];

export const mockJsonGPTRaw: any = [
    {
      "id": "appcommon",
      "items": {
        "Affinity2:AppCommon": {
          "description": "Common settings for all applications",
          "preferStoreAsJson": false
        }
      },
      "etag": "4567",
      "tags": ["Infrastructure", "Category:System"]
    },
    {
      "id": "random-id-1",
      "items": {
        "CompanyName:SettingString:Node1": {
          "description": "Setting description 1",
          "preferStoreAsJson": true
        },
        "CompanyName:SettingString:Node2": {
          "description": "Setting description 2",
          "preferStoreAsJson": false
        },
        "Affinity1:AppSettings": {
          "description": "Application settings for Affinity 1",
          "preferStoreAsJson": true
        },
        "Affinity3:NodeSettings": {
          "description": "Node-specific settings for Affinity 3",
          "preferStoreAsJson": false
        }
      },
      "etag": "7890"
    },
    {
      "id": "random-id-2",
      "items": {
        "CompanyName:SettingString:Node3": {
          "description": "Setting description 3",
          "preferStoreAsJson": true
        },
        "CompanyName:SettingString:Node4": {
          "description": "Setting description 4",
          "preferStoreAsJson": false
        },
        "Affinity1:AppSettings": {
          "description": "Application settings for Affinity 1",
          "preferStoreAsJson": true
        },
        "Affinity3:NodeSettings": {
          "description": "Node-specific settings for Affinity 3",
          "preferStoreAsJson": false
        }
      },
      "etag": "1234"
    }
  ]



export const mockJsonDataArray: any = [
    {
        key: 1,
        name: 'Affinity',
        description: 'The Affinity Productions configuration root',
        children: [
            {
                key: 11,
                name: 'App Common',
                description: 'Common settings for all applications',
            },
            {
                key: 12,
                name: 'BlobServiceUri',
                description: 'The Url of the Azure BlobService that is provided by the AppCommon infrastructure to other libs',
                type: "uri",
                children: [
                    {
                        key: 121,
                        name: 'sub uri',
                        description: 'demo useage',
                        type: "uri",
                    },
                ],
            },
            {
                key: 13,
                name: 'Endpoints',
                description: 'The home grown affinity service discovery static registry',
                children: [
                    {
                        key: 131,
                        name: '@{}',
                        description: 'The name of the entry in the service discovery static registry',
                        children: [
                            {
                                key: 1311,
                                name: 'Endpoint',
                                type: 'uri',
                                description: "The Virtual URL that should be matached to use this entry for connectivity",
                            },
                            {
                                key: 1312,
                                name: 'HealthCheckProviders',
                                description: 'The list of providers that ensures that the resolved URL is healthy.',
                                type: 'string',
                            },
                        ],
                    },
                ],
            },
        ],
    },
    {
        key: 2,
        name: 'InternalAspNet',
        description: "The options for the affinity asp.net health check provider.",
    },
]

const data = [
    {
        key: 1,
        name: 'Affinity',
        description: 'The Affinity Productions configuration root',
        children: [
            {
                key: 11,
                name: 'App Common',
                description: 'Common settings for all applications',
            },
            {
                key: 12,
                name: 'BlobServiceUri',
                description: 'The Url of the Azure BlobService that is provided by the AppCommon infrastructure to other libs',
                type: "uri",
                children: [
                    {
                        key: 121,
                        name: 'sub uri',
                        description: 'demo useage',
                        type: "uri",
                    },
                ],
            },
            {
                key: 13,
                name: 'Endpoints',
                description: 'The home grown affinity service discovery static registry',
                children: [
                    {
                        key: 131,
                        name: '@{}',
                        description: 'The name of the entry in the service discovery static registry',
                        children: [
                            {
                                key: 1311,
                                name: 'Endpoint',
                                type: 'uri',
                                description: "The Virtual URL that should be matached to use this entry for connectivity",
                            },
                            {
                                key: 1312,
                                name: 'HealthCheckProviders',
                                description: 'The list of providers that ensures that the resolved URL is healthy.',
                                type: 'string',
                            },
                        ],
                    },
                ],
            },
        ],
    },
    {
        key: 2,
        name: 'InternalAspNet',
        description: "The options for the affinity asp.net health check provider.",
    },
];