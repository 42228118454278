import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import {
    InteractionType,
    InteractionRequiredAuthError,
    InteractionStatus,
} from "@azure/msal-browser";
import { MsalAuthenticationTemplate, useMsal } from "@azure/msal-react";
import './App.less'
import logo from './logo.svg';
// import './App.css';
import { AppBody, AppHeader, AppNavigator } from "./components";
import {
    AppContext,
    setShowFullMenu,
    setUserInfo,
    setUserLink,
    setUserNavLinks,
    setConfigRaw
} from "./context";
import "./remote";
import {fetchDashboardConfig, fetchUserConfig, getConfigRaw} from "./remote";
import axios from "axios";
import {ConfigPortalTree} from "./components/ConfigPortal/Tree/ConfigPortalTree";
import {ConfigJsonEditor} from "./components/ConfigPortal/ConfigJsonEditor";
import { AppLoader } from './components/AppLoader';

const loginRequest = {
    scopes: [`https://${process.env.REACT_APP_AAD_TENANT_NAME}.onmicrosoft.com/platform-api/ui.read.common`]
};

function App() {
    const { instance, inProgress, accounts } = useMsal();
    const [apiData, setApiData] = useState<any>(null);

    const { state, dispatch } = React.useContext(AppContext);
    const [configLoaded, setConfigLoaded] = React.useState(false);
    const [accessToken, setAccessToken] = React.useState('');

    useEffect(() => {
        const accessTokenRequest = {
            // TODO: Switch the set of scopes depending on type of login (affinity global admin vs affinity user)
            scopes: [
                `https://${process.env.REACT_APP_AAD_TENANT_NAME}.onmicrosoft.com/frontline-api/ui.read.common`,
                `https://${process.env.REACT_APP_AAD_TENANT_NAME}.onmicrosoft.com/frontline-api/central-config.read-write.company`,
                `https://${process.env.REACT_APP_AAD_TENANT_NAME}.onmicrosoft.com/frontline-api/central-config.read-write.all`,
                `https://${process.env.REACT_APP_AAD_TENANT_NAME}.onmicrosoft.com/frontline-api/central-config.metadata-read.company`,
                `https://${process.env.REACT_APP_AAD_TENANT_NAME}.onmicrosoft.com/frontline-api/central-config.metadata-read-write.all`,
            ],
            account: accounts[0],
        };
        if (!apiData && inProgress === InteractionStatus.None) {
            instance
                .acquireTokenSilent(accessTokenRequest)
                .then((accessTokenResponse) => {
                    // Acquire token silent success
                    let accessToken = accessTokenResponse.accessToken;
                    // console.log(accessToken)
                    setAccessToken(accessToken)
                    axios.defaults.headers.common = {'Authorization': `Bearer ${accessToken}`}

                    // Call your API with token
                    // callApi(accessToken).then((response) => {
                    //     setApiData(response);
                    // });
                })
                .catch((error) => {
                    if (error instanceof InteractionRequiredAuthError) {
                        // instance.acquireTokenRedirect(accessTokenRequest);
                    }
                    console.log(error);
                });
        }
        // setApiData({ userName: userName });
        // var tokenPromise = instance.acquireTokenSilent({
        //     ...loginRequest,
        //     account: accounts[0]
        // }).then(v => {
        //     console.log()
        //     const userConfigD = fetchUserConfigData(v.accessToken)

        // });
    }, [instance, accounts, inProgress]);

    React.useEffect( () => {
        if( !accessToken) {
            return;
        }

        Promise.all([fetchDashboardConfig(), fetchUserConfig()])
            .then(([dashboardConfig, userConfig]) => {
                if (!userConfig) {
                    return;
                }

                if (userConfig?.user) {
                    dispatch(setUserInfo(userConfig.user));
                    // dispatch(setConfigRaw(configRaw));
                }

                if (dashboardConfig.navLinks && dashboardConfig.userLink) {
                    dispatch(setUserNavLinks(dashboardConfig.navLinks));
                    dispatch(setUserLink(dashboardConfig.userLink));
                }
            })

        getConfigRaw().then((configRaw) => {
            dispatch(setConfigRaw(configRaw))
            setConfigLoaded(true);
        })
    }, [accessToken])

    return (
        <div>
            <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
                <AppHeader
                    title=""
                    onMenuClick={() =>
                        dispatch(setShowFullMenu(!state.showFullMenu))
                    }
                />
                <AppNavigator
                    showFullMenu={state.showFullMenu}
                    onCollapseBtnClick={() =>
                        dispatch(setShowFullMenu(!state.showFullMenu))
                    }
                />

                {configLoaded &&
                    <BrowserRouter>
                        <div>
                            <Routes>
                                <Route path="/" element={<AppBody resource={''} />} />
                                <Route path="/config" element={<AppBody resource={''} />} />
                                <Route path="/tree" element={<ConfigPortalTree resource={''} />} />
                                <Route path="/metadata" element={<ConfigPortalTree resource={''} />} />
                                <Route path="/json-edit" element={<ConfigJsonEditor resource={''}/>} />
                            </Routes>
                        </div>
                    </BrowserRouter>
                }
            </MsalAuthenticationTemplate>

        </div>


    );
}

export default App;
