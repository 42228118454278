import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.less';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { ConfigProvider } from "antd";
import {AppLoader} from "./components/AppLoader";
import {AppProvider} from "./context";

// Configuration object constructed.
const config = {
  auth: {
    clientId: process.env.REACT_APP_AAD_CLIENT_ID!,
    authority: `https://${process.env.REACT_APP_AAD_TENANT_NAME}.b2clogin.com/${process.env.REACT_APP_AAD_TENANT_NAME}.onmicrosoft.com/B2C_1A_SIGNUP_SIGNIN`,
    knownAuthorities: [`${process.env.REACT_APP_AAD_TENANT_NAME}.b2clogin.com`]
  }
};
console.log(config)
// create PublicClientApplication instance
const publicClientApplication = new PublicClientApplication(config);


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  // <React.StrictMode>
    <MsalProvider instance={publicClientApplication}>
        <ConfigProvider prefixCls="ss-app">
            {/*<React.StrictMode>*/}
            <AppProvider>
                <React.Suspense fallback={<AppLoader tip="Loading..." />}>
                    {/*<h1>Test</h1>*/}
                    <App />
                </React.Suspense>
            </AppProvider>

            {/*</React.StrictMode>*/}
        </ConfigProvider>
    </MsalProvider>
  // </React.StrictMode>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
