import React, {useCallback, useEffect, useState} from "react";
import {Table, Typography, Select, Input, Space, Modal, Button, Checkbox, Tree, TreeProps} from "antd";
import {formatData, formatTreeFrontEnd} from "../format";
import {bkData} from "../mock-data";
import {AppContext, setConfigRaw} from "../../../context";
import {ColumnsType} from "antd/es/table";
import {getConfigMetadataAreas, getConfigRaw, setConfigItems} from "../../../remote";
import {ConfigPModal} from "./ConfigPortalModal";
import {columnsSetting} from "../constant";
import {AddBranch} from "./AddBranch";
import {useForm} from "antd/es/form/Form";
import { Link } from 'react-router-dom';
import Icon, { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { ReactComponent as Pencil } from "../../../assets/ico/pencil.svg";


const { Title } = Typography;
const { Option } = Select;
const { Search } = Input;
const { Column, ColumnGroup } = Table;

export interface DataType {
    key: React.Key;
    name: string;
    metaData: string;
    category: string;
    valueType: string;
    value: string;
    defValue: string;
    companies: string;
    payPoint: string;
}

export const ConfigPortal = () => {
    const [categoriesOptions, setCategoriesOptions] = useState<any>([]);
    const [selectedCategory, setSelectedCategory] = useState<string | null>()
    const [selectedCompany, setSelectedCompany] = useState<any>()
    const [companyOptions, setCompanyOptions] = useState<any>([]);
    const [searchKey, setSearchKey] = useState<any>()
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
    const [modalData, setModalData] = useState<any>({})
    const [tdData, setTdData] = useState<any[]>([])
    const [columns, setColumn] = useState<ColumnsType<DataType>>()
    const [originalTableData, setOriginalTableData] = useState<any[]>([])
    const [modalMode, setModalMode] = useState<string>('Edit')
    const { state, dispatch } = React.useContext(AppContext);



    useEffect(() => {
        const findAction = columnsSetting.find((setting: any) => {
            if (setting.title === 'Action') {
               return setting
            }
        })

        if (!findAction) {
            columnsSetting.push(
                {
                    title: 'Action',
                    key: 'operation',
                    fixed: 'right',
                    width: 180,
                    render: (data: any) => {
                        return <span>
                            <Button 
                                className="config-btn lightblue-btn"
                                onClick={() => {
                                    setModalMode('Edit')
                                    showModal(data)
                                }}
                                icon={<Icon component={Pencil} />}>Edit</Button>
                            <Button className="config-btn green-btn"
                                    style={{ height: '40px', width: '40px' }}
                                    onClick={() => {
                                        setModalMode('Add')
                                        showModal(data)
                                    }}
                                    icon={<PlusOutlined />}></Button>
                            <Button className="config-btn red-btn"
                                    style={{ height: '40px', width: '40px' }}
                                    onClick={() => {
                                        setModalMode('Delete')
                                        showModal(data)
                                    }}
                                    icon={<DeleteOutlined />}></Button>
                        </span>

                    }
                }
            ) ;
            setColumn(columnsSetting);
        }
    }, [])

    useEffect(() => {
        console.log('useEffect', state.configRaw)

        const tdData = formatData(state.configRaw);
        let categoriesOptionsArray = []
        let companyOptionsArray = []

        for (let data of tdData) {
            if (data.category) {

                const findResult = categoriesOptionsArray.find((arrData: any) => {
                    return arrData.value === data.category
                })

                if (!findResult) {
                    categoriesOptionsArray.push({
                        value: data.category,
                        label: data.category
                    })
                }
            }

            if (data.companies && data.companies !== '') {
                for (let company of data.companies) {
                    if (findCompany(companyOptionsArray, company)) {
                        //
                    } else {
                        companyOptionsArray.push({
                            value: company,
                            label: company
                        })
                    }
                    
                }
            }

            if (typeof data.value === 'boolean') {
                if (data.value === false) {
                    data.value = 'false'
                } else if (data.value === true) {
                    data.value = 'true'
                }
            }
        }

        setCategoriesOptions(categoriesOptionsArray)
        setCompanyOptions(companyOptionsArray)

        setTdData(tdData)
        setOriginalTableData(tdData);
        console.log('update config raw', state.configRaw)
        // onSearch('Front')
        if (selectedCompany || selectedCategory || searchKey) {
            setTDdataByFilter()
        }
    }, [state.configRaw])


    useEffect(() => {
        setTDdataByFilter()
       
    },[selectedCompany, selectedCategory, searchKey])

    function findCompany(companyOptionsArray: any[], company: string) {
        return companyOptionsArray.find(option => option.value === company);

    }

    function setTDdataByFilter() {
         // formatData(bkData)
         if(originalTableData.length === 0 && !selectedCompany && !selectedCategory && !searchKey) {
            return;
        }
        if (!selectedCompany && !selectedCategory && !searchKey) {
            setTdData(originalTableData)
            // setTdData(tdData.map(data => {
            //     data.companies = data.companies.toString()
            //     return data
            // }))
            return;
        }

        let tableDataSourceFiltered: any[] = originalTableData
        if (selectedCompany && selectedCategory) {
            tableDataSourceFiltered = originalTableData.filter((data: any) => {
                return data.companies?.indexOf(selectedCompany) > -1 && data.category === selectedCategory;
            })
        } else if (selectedCategory) {
            tableDataSourceFiltered = originalTableData.filter(data => {
                return data.category === selectedCategory;
            })
        } else if (selectedCompany !== null && selectedCompany !== undefined) {
            tableDataSourceFiltered = originalTableData.filter((data: any) => {
                if (selectedCompany !== null && selectedCompany !== undefined) {
                    return data.companies?.indexOf(selectedCompany) > -1;
                }
            })
        }

        if (searchKey) {
            tableDataSourceFiltered = tableDataSourceFiltered.filter((data: any) => {
                return data.name.indexOf(searchKey) > -1 || data.description.indexOf(searchKey) > -1;
            })
        }

        setTdData(tableDataSourceFiltered)
    }

    function clearCategory() {
        selectCategory('')
    }

    function selectCategory(category: any) {
        setSelectedCategory(category)
    }

    function clearCompany() {
        selectCompany();
    }

    function selectCompany(company?: any) {
        setSelectedCompany(company)
    }

    function onSearch(newSearchKey: any) {
        setSearchKey(newSearchKey)
    }

    const showModal = (data: any) => {
        setModalData({name: data.name,
            input: data.value,
            values: data.values.map((val: any) => {
                return {
                    ... val,
                    modalValue: undefined
                }
            }),
            type: data.valueType})
        setIsModalOpen(true);
    };

    return (
        <div className="App">
            <header className="flex flex-jstart ml-4">
                <span className="page-title">
                    Configuration Portal
                </span>
            </header>
            <div className="App-body">
                <div className="d-flex flex-jbetween">
                    <div className="mt-4">
                        <span>Search:</span>
                        <Search className="ml-2" style={{ width: 200 }}
                                placeholder={'Type to search.'}
                                allowClear
                                onSearch={onSearch}
                        />
                        {/* <span className="ml-4">Category:</span>

                        <Select className="ml-2"
                                allowClear
                                style={{ width: 150 }}
                                onClear={clearCategory}
                                onChange={selectCategory}
                                options={categoriesOptions}>
                        </Select> */}

                        <span className="ml-4">Company:</span>
                        <Select className="ml-2"
                                allowClear
                                style={{ width: 150 }}
                                onClear={clearCompany}
                                onChange={selectCompany}
                                options={companyOptions}>
                        </Select>
                        {/*<Button onClick={() => test()}> TEST</Button>*/}
                    </div>
                    <div className="d-flex flex-acenter">
                        <AddBranch></AddBranch>
                    </div>
                    
                </div>

                {
                    !!columns &&
                    <div className="mt-4">
                        <Table dataSource={tdData} columns={columns} />
                    </div>
                }

            </div>
            <ConfigPModal 
                        modalModeInput={modalMode}
                        modalDataInput={modalData}
                        modalOpenInput={isModalOpen} closeModal={() => setIsModalOpen(false)} ></ConfigPModal>

        </div>
    )
}