import React from "react";
import { Spin, SpinProps } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

interface Props extends SpinProps {
    position?: "top" | "middle" | "bottom";
}

const antico = <LoadingOutlined style={{ fontSize: 64 }} spin />;

export const AppLoader: React.FC<Props> = ({ position, ...props }) => (
    <Spin
        {...props}
        indicator={antico}
        className={`ss-app-loader ss-app-${position || "middle"}-loader`}
    >
        {props.children}
    </Spin>
);
