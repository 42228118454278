import React from "react";
import { Alert, Button } from "antd";
import { AppHeaderBody } from ".";


import {
    AppContext,
} from "../context";
import moment from "moment";
import {ConfigPortal} from "./ConfigPortal/ValueTable/ConfigPortal";

interface IProps {
    resource: any;
}

export const AppBody = ({ resource }: IProps) => {
    const { state, dispatch } = React.useContext(AppContext);

    return (
        <div className="ss-app-body">
            <ConfigPortal></ConfigPortal>
        </div>
    );
};
