import React from "react";
import {Button, Dropdown, Menu} from "antd";
import type { MenuProps } from 'antd';
import { AppIcon } from "./SVGFiles";
import { AppContext } from "../context";
import { useMsal } from "@azure/msal-react";
import { getPostLogoutUrlByEnv } from "../utils/getUrl";

interface Props {
    onMenuClick: () => void;
}

export const UserTopNav: React.FC<Props> = ({ onMenuClick }) => {
    const { state } = React.useContext(AppContext);
    const { user, userLink } = state;

    const { instance, inProgress, accounts } = useMsal();

    const logout = () => {
        instance.logoutRedirect({
            account: accounts[0],
            postLogoutRedirectUri: getPostLogoutUrlByEnv()
        })
    }

    const menus: MenuProps['items'] = [{
        key: '1',
        label:
            <span>
                {userLink && (
                    <a role={"button"} href={userLink.link}>
                        <AppIcon
                            asset={userLink.icon}
                            stroke="#000000"
                            height="16"
                            width="16"
                        />
                        <span>{userLink.label}</span>
                    </a>
                )}
            </span>

    }, {
        key: '2',
        label: <a
                role={"button"}
                href={process.env.REACT_APP_LOGOUT_URL as string}
                onClick={() => logout()}
                >
                    <AppIcon
                        asset={"logout"}
                        stroke="#000000"
                        height="16"
                        width="16"
                       
                    />
                    <span>Logout</span>
                </a>
    }]
        // <Menu className="ss-app-dd-profile">
        //     {userLink && (
        //         <Menu.Item key="0">
        //             <a role={"button"} href={userLink.link}>
        //                 <AppIcon
        //                     asset={userLink.icon}
        //                     stroke="#000000"
        //                     height="16"
        //                     width="16"
        //                 />
        //                 <span>{userLink.label}</span>
        //             </a>
        //         </Menu.Item>
        //     )}
        //     <Menu.Item key="1">
        //         <a
        //             role={"button"}
        //             href={process.env.REACT_APP_LOGOUT_URL as string}
        //         >
        //             <AppIcon
        //                 asset={"logout"}
        //                 stroke="#000000"
        //                 height="16"
        //                 width="16"
        //             />
        //             <span>Logout</span>
        //         </a>
        //     </Menu.Item>
        // </Menu>
    // )];

    const employeeName = React.useMemo(() => {
        if (user) {
            const { lastName, firstName } = user;
            return (
                <span className="ss-app-user-name">
                    {[firstName, lastName].join(" ")}
                </span>
            );
        }
        return "";
    }, [user]);

    // const employeeAcro = React.useMemo(() => {
    //     if (user) {
    //         const { lastName, firstName } = user;
    //         return (
    //             <Avatar size={44} className="f-12 f-bold m-l-4 m-r-4">
    //                 {[firstName[0], lastName[0]].join("")}
    //             </Avatar>
    //         );
    //     }
    //     return "";
    // }, [user]);

    return (
        <div className="ss-app-user-top-nav">
            <span className="ss-app-user-name">
                {employeeName}
                <Dropdown
                    menu={{items: menus}}
                    trigger={["click"]}
                    arrow
                    placement="bottomRight"
                    overlayClassName="ss-app-profile-overlay"
                >
                    <span className="ss-app-user-dd">
                        {/* {employeeAcro} */}
                        <AppIcon asset="arrow-down" height="16" width="16" />
                    </span>
                </Dropdown>
            </span>

            {/* <Button
                className="m-l-4"
                type="link"
                shape="circle"
                icon={
                    <AppIcon
                        asset="setting"
                        stroke="#272727"
                        height="30"
                        width="30"
                    />
                }
            /> */}
            <Button
                className="m-l-4"
                type="link"
                shape="circle"
                icon={<AppIcon asset="help" height="30" width="30" />}
                onClick={() =>
                    window.open(
                        process.env.REACT_APP_HELP_URL as string,
                        "_blank"
                    )
                }
            />
            <Button
                className="m-l-4 menu-burger"
                type="link"
                shape="circle"
                icon={<AppIcon asset="burger-menu" />}
                onClick={() => onMenuClick()}
            />
        </div>
    );
};
