import React from "react";
// import Logo from "../logo.png";
import { UserTopNav } from ".";
import { AppContext } from "../context";
import { Button } from "antd";
import { AppIcon } from "./SVGFiles";

interface Props {
    title: string;
    onMenuClick: () => void;
}

export const AppHeader: React.FC<Props> = ({ title, onMenuClick }) => {
    const { state } = React.useContext(AppContext);
    console.log('props', title)
    console.log('props', state)

    let logoUrl 
    let tempUrlArr = ['localhost', 'config', 'testaffinitylogon', 'metadata', 'portal']
    if ( tempUrlArr.some((str) => {
        const includes = window.location.href.includes(str)
        return includes
    })) {
        
        logoUrl = `https://cdn.jsdelivr.net/gh/affinityteam/www-assets/v1/images/logo/affinity1_300_90.png`
    } else {

        // todo: plan fix the Dashboard_Url by building new api in platform api
        logoUrl  = `${process.env.REACT_APP_DASHBOARD_URL as string}/Root/Logo?company=${state.user?.legacyCompanyNo}&employee=${
            state.user?.legacyEmployeeNo
        }`
    }
    return (
        <header className="ss-app-main-header">
            <div>
            <h1>
                    <Button className="menu-burger va-middle" type="link" shape="circle" icon={<AppIcon asset="burger-menu" />} onClick={() => onMenuClick()} />
                    <span className="va-middle">
                        <img
                            src={logoUrl}
                            alt="Affinity - making work life easy"
                            height={48}
                        />
                        {window.location.href.includes("testaffinitylogon") && <span>TE</span>}
                    </span>
                </h1>
                <div>
                    <UserTopNav onMenuClick={onMenuClick} />
                </div>
            </div>
        </header>
    );
};
