import axios from "axios";
import moment from "moment";

import {
    DateRange,
    NavigationRoute,
    Employee,
} from "../types";

const { REACT_APP_PLATFORM_API_URL, REACT_APP_CENTRAL_CONFIG_API_URL, REACT_APP_LOGIN_URL } = process.env;

axios.interceptors.request.use((config) => {
    let authHeader: any = {
        // "Cache-Control": "no-cache",
    };

    config.withCredentials = true;
    config.headers = {
        ...config.headers,
        ...authHeader,
        "content-type": "application/json; charset=utf-8",
    };

    config.baseURL = REACT_APP_PLATFORM_API_URL;

    return config;
});

axios.interceptors.response.use(
    (res) => {
        switch (res.status) {
            case 401: // Unauthorize
                // window.location.href = `${REACT_APP_LOGIN_URL}?redirectUrl=${window.location.href}`;
                break;
        }
        // Return data API Content
        return res;
    },
    (errors) => {
        if (!errors.response) {
            console.log('err', errors)
            return Promise.reject(errors)
        }
        switch (errors.response.status) {
            case 401:
                // window.location.href = `${REACT_APP_LOGIN_URL}?redirectUrl=${window.location.href}`;
                return;

            case 404:
                return Promise.resolve(errors);
        }
        return Promise.reject(errors);
    }
);


export function getConfigMetadataAreas() {
    return axios.get(`${REACT_APP_CENTRAL_CONFIG_API_URL}/api/v1/config-metadata/areas`)
}

export function getAreaContent() {
    return  axios.get(`${REACT_APP_CENTRAL_CONFIG_API_URL}/api/v1/config-metadata/areas-content`);
}

export function postAreaContent(data: any) {
    return axios.put(`${REACT_APP_CENTRAL_CONFIG_API_URL}/api/v1/config-metadata/areas-content`, data)
    
}

interface IConfigDataProps {
    user: Employee;
    navLinks?: NavigationRoute[];
    userLink?: NavigationRoute;
}

export function fetchDashboardConfigData() {
    const config = fetchDashboardConfig().catch(e => {
        console.log('e', e)
    });
    return {
        config: wrapPromise(config),
    };
}

export function fetchUserConfigData() {
    const config = fetchUserConfig().catch(e => {
        console.log('e', e)
    });
    return {
        config: wrapPromise(config),
    };
}

export const fetchDashboardConfig = async (): Promise<any> => {
    const { links, userLink } = await getUserNavLinks();
    return {
        navLinks: links,
        userLink,
    }
}

export const fetchUserConfig = async (): Promise<any> => {
    const user = await getUserInfo();
    return {
        user: user,
    }
}

export const getUserInfo = () => {
    return axios
        .get(`${REACT_APP_PLATFORM_API_URL}/api/v1/common-ui/user-info`)
        .then((res) => res.data);
};

export const getUserNavLinks = async (): Promise<{
    links: NavigationRoute[];
    userLink: NavigationRoute;
}> => {
    return axios
        .get(`${REACT_APP_PLATFORM_API_URL}/api/v1/common-ui/dashboard-config`)
        .then((res) => {
            const config: any = res.data;
            const links: NavigationRoute[] = [
                {
                    link: process.env.REACT_APP_DASHBOARD_URL as string,
                    label: "Dashboard",
                    icon: "dashboard",
                    id: "dashboard",
                    enabled: true,
                },
            ];
            config.apps.forEach((app: any) => {
                if (app.link) {
                    const { icon, text, enabled, external, url, menu, name } =
                        app.link;
                    let subLinks: NavigationRoute[] = [];
                    if (menu.length) {
                        menu.forEach((m: any) => {
                            subLinks.push({
                                id: m.name,
                                icon: m.icon,
                                label: m.text,
                                link: m.url,
                                enabled: m.enabled,
                                external: m.external,
                                subLinks,
                            });
                        });
                    }
                    links.push({
                        id: name,
                        icon: icon,
                        label: text,
                        link: url,
                        enabled: enabled,
                        external: external,
                        subLinks,
                    });
                }
            });

            const userLink: NavigationRoute = {
                id: config.forms[0].name,
                icon: config.forms[0].icon,
                label: config.forms[0].text,
                link: config.forms[0].url,
                enabled: config.forms[0].enabled,
                external: config.forms[0].external,
            };
            // links.push(userLink);
            return {
                links,
                userLink,
            };
        });
};

export const getConfigRaw = () => {
    return axios
        .get(`${REACT_APP_CENTRAL_CONFIG_API_URL}/api/v1/config-editor/items-view`, {
            params: {
                PopulateMetadataDefaults: true,
            }
        })
        .then((res) => res.data);
};

export const setConfigItems = (value: any) => {
    // 'https://platform-api.l1.affinitylogon.net/api/v1/config-editor/items
    // return axios.put(`${REACT_APP_PLATFORM_API_URL}/api/v1/config-editor/items`, {
    //     layers: {
    //         "1": {"key": "global"},
    //     },
    //     "configRoot": {
    //         "nodes": {
    //             "ConfigEngineTesting": {
    //                 "nodes": {
    //                     "FrontendTest2": {
    //                         "nodes": {
    //                             "SimpleString": { "values": {"1": "lab1"}}                    }
    //                     },
    //                 },
    //             },
    //         }
    //     }
    // })
    return axios.put(`${REACT_APP_CENTRAL_CONFIG_API_URL}/api/v1/config-editor/items`, value)
}


export const wrapPromise = (promise: Promise<any>) => {
    let status = "pending";
    let result: any;
    let suspender = promise.then(
        (res) => {
            status = "success";
            result = res;
        },
        (err) => {
            status = "error";
            result = err;
        }
    );

    return {
        read() {
            if (status === "pending") {
                throw suspender;
            } else if (status === "error") {
                throw result;
            }
            return result;
        },
    };
};
