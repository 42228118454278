import {ColumnsType} from "antd/es/table";
import {Button} from "antd";
import React from "react";
import {DataType} from "./ValueTable/ConfigPortal";
import {setConfigItems} from "../../remote";

export const columnsSetting: ColumnsType<DataType> = [
    {
        title: 'Technical Name',
        dataIndex: 'name',
        key: 'name',
        width: 220,
        // textWrap: 'word-break',
    },
    {
        title: 'Description',
        dataIndex: 'metaData',
        key: 'metaData',
        width: 400,
    },
    // {
    //     title: 'Category',
    //     dataIndex: 'category',
    //     key: 'category',
    //     width: 120,
    // },
    {
        title: 'Data Type',
        dataIndex: 'valueType',
        key: 'valueType',
        width: 120,
    },
    {
        title: 'Value',
        dataIndex: 'value',
        key: 'value',
        width: 180,
    },
    // {
    //     title: 'Default Value',
    //     dataIndex: 'defValue',
    //     key: 'defValue',
    //     width: 140,
    // },
    {
        title: 'Companies',
        dataIndex: 'companies',
        key: 'companies',
        width: 110,
        render: (companies) => {
            if (!companies) return
            return companies.join()
        }
    },
    {
        title: 'Pay Point',
        dataIndex: 'PayPoint',
        key: 'payPoint',
        width: 100,
    }
    // Add following action in the Config portal file.
    // {
    //     title: 'Action',
    //     key: 'operation',
    //     fixed: 'right',
    //     width: 100,
    //     render: (data: any) => {
    //         return <Button size="small" onClick={() => showModal(data)} >Edit</Button>
    //     }
    //     ,
    // },

];

export const ValueType = [
    {
        value: 'String',
        label: 'String'
    },
    {
        value: 'Integer',
        label: 'Integer'
    },
    {
        value: 'Double',
        label: 'Double'
    },
    {
        value: 'Uri',
        label: 'Uri'
    },
    {
        value: 'Guid',
        label: 'Guid'
    },
    {
        value: "Boolean",
        label: "Boolean"
    }
]

export const ConfigTypes = ["String", "Integer", "Double", "Uri", "Guid", "Boolean"]

// export const testSetConfigItems = () => {
//     const testValue = {
//         layers: {
//             // "1": {"key": "global"},
//             "2": {"company": "6623", "key": "company-config"},
//         },
//         "configRoot": {
//             "nodes": {
//                 "ConfigEngineTesting": {
//                     "nodes": {
//                         "FrontendTest": {
//                             "nodes": {
//                                 "SimpleString": {
//                                     "overrideMarks": ["2"]
//                                 }
//                             }
//                         },
//                     },
//                 },
//             }
//         }
//     }
//     setConfigItems(testValue)
// }