import {Modal, Form, Input, Select, Button, Tree, TreeProps} from 'antd';
import React, {useEffect, useState} from "react";
import {formatTreeFrontEnd} from "../format";
import {AppContext, setConfigRaw} from "../../../context";
import {getConfigRaw, setConfigItems} from "../../../remote";

const { Option } = Select;


export const AddBranch = () => {
    const { state, dispatch } = React.useContext(AppContext);

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [branchParent, setBranchParent] = useState('Test parent');
    const [titlesArr, setTitlesArr] = useState<any[]>();
    const [testData, setTestData] = useState<any[]>([])
    const [newBranch, setNewBranch] = useState<string>('')
    const [newValue, setNewValue] = useState<string>('')
    const [options, setOptions] = useState<any[]>([])

    useEffect(() => {
        const testData = formatTreeFrontEnd(state.configRaw.configRoot, state.configRaw.metadataRoot)
        setTestData([testData])
    }, [])


    const getKeyStringFromSelected = (keys: string[], keyIndex: number, testData: any, titleString: string): string => {
        let newTestData
        if (keys.length > keyIndex) {
            newTestData = testData.children[Number(keys[keyIndex]) - 1 ]
            titleString = titleString + newTestData.title;
            keyIndex = keyIndex + 1
            return getKeyStringFromSelected(keys, keyIndex, newTestData, titleString)
        } else {
            // found leaf
            return titleString;
        }

    }
    const onSelect: TreeProps['onSelect'] = (selectedKeys: any, info) => {
        if (selectedKeys && selectedKeys[0]) {
            let targetTree = testData[0]

            let splitResult = selectedKeys[0].split(',').map((key: string, index: number) => {
                targetTree = targetTree.children[Number(key) - 1]
                return targetTree
            })

            const titles = splitResult.map((result: any) => {
                return result.title
            })
            const options = Object.keys(splitResult[splitResult.length - 1].wildCardNodes).map((key: string) => {
                return {
                    label: key,
                    value: key
                }
            })
            setOptions(options);
            setTitlesArr(titles);
            setBranchParent(titles.join(':'))
        }
    };

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        let requestObject: any = {
            "configRoot": {
                "nodes": {}
            },
            'layers': {
                1: {key: 'global'}
            }
        }

        let targetObject = requestObject.configRoot.nodes
        for (let title of titlesArr!) {
            targetObject[title] = {
                nodes: {}
            }
            targetObject = targetObject[title].nodes
        }

        targetObject[newBranch] = {
            nodes: {
                [newValue]: {
                    nodes: {
                        values: {
                            1: 'value'
                        }
                    }
                }
            }
        }

        setConfigItems(requestObject)
            .then(() => {
                return getConfigRaw()
            })
            .then((configRaw) => {
                dispatch(setConfigRaw(configRaw))
            })
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const onSetNewBranch = (val: string) => {
        setNewBranch(val)
    }

    const handleSelectValue = (val: any) => {
        setNewValue(val)
    }

    return (
        <div>
            <Button className='lightblue-btn' onClick={showModal}>
                Create Section
            </Button>
            <Modal
                title="Create Section"
                width={1000}

                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}>
                <div className="grid create-branch-grid">
                    <Tree
                        showIcon
                        className="tree-height"
                        selectable={false}
                        onSelect={onSelect}
                        treeData={testData}
                    />
                    <div>
                        <div className="mt-4 add-branch-grid">
                            <label>Selected section:</label>
                            <label>{branchParent}</label>
                        </div>
                        <div className="mt-4 add-branch-grid">
                            <label>New Branch:</label>
                            <Input style={{width: 300}}
                                   onChange={(e: any) => onSetNewBranch(e.target.value)}></Input>
                        </div>
                        <div className="mt-4 add-branch-grid">
                            <label>Select Type:</label>
                            <Select placeholder="Select a value type"
                                    options={options}
                                    onChange={handleSelectValue}>

                            </Select>
                        </div>
                    </div>
                </div>

            </Modal>
        </div>
    );
}


