export const getPostLogoutUrlByEnv = (): string => {
    return window.location.protocol + "//" + window.location.hostname + (window.location.port ? ":" + window.location.port : "");

    // todo: complete following with environment setup
    
    // if (process.env.NODE_ENV === 'development') {
    //     return process.env.REACT_APP_LOCALURL as string
    // }

    // return 'https://affinitylogon.com/'
}