import React from "react";
import { Collapse } from "antd";

import { AppIcon } from "./SVGFiles";

const { Panel } = Collapse;
interface Props {
    title: React.ReactNode;
    ghost?: boolean;
    extra?: React.ReactNode;
    markerColor?: string;
    className?: string;
    hasBlockSymbol?: boolean;
    collapsed?: boolean;
    showArrow?: boolean;
    children: React.ReactNode;
}

export const AppCollapse: React.FC<Props> = ({
    title,
    ghost = false,
    children,
    extra,
    className,
    markerColor,
    hasBlockSymbol = false,
    collapsed = true,
    showArrow = true,
}) => {
    const backgroundColor = markerColor;
    return (
        <Collapse
            ghost={ghost}
            defaultActiveKey={collapsed ? ["1"] : undefined}
            className={className}
            expandIcon={({ isActive }) => (
                <>
                    {isActive ? (
                        <AppIcon asset="arrow-down" />
                    ) : (
                        <AppIcon asset="arrow-up" />
                    )}
                </>
            )}
            expandIconPosition="right"
        >
            <Panel
                header={
                    <>
                        {hasBlockSymbol && (
                            <span
                                className="symbol-block"
                                style={{ backgroundColor }}
                            ></span>
                        )}
                        <span>{title}</span>
                    </>
                }
                showArrow={showArrow}
                key="1"
                extra={extra}
            >
                {children}
            </Panel>
        </Collapse>
    );
};
