import {Button, Input, Select} from "antd";
import React, {useEffect, useState} from "react";


interface IProps {
    modalDataInput: any;
    onSetCreateData: (createData: CreateData) => void;
}

export interface CreateData {
    company: string,
    value: string | boolean,
    type: string
}

export const CreateConfig = (props: IProps) => {
    const [createData, setCreateData] = useState<CreateData>({
        company: '',
        value: '',
        type: ''
    })

    useEffect(() => {
        setCreateData({
            ...createData,
            type: props.modalDataInput.type
        })
        
    }, [props.modalDataInput])

    function onSetCompany(company: string) {
        const newCreateData = {
            ...createData,
            company: company
        }
        setCreateData(newCreateData)
        props.onSetCreateData(newCreateData)
    }

    function onSetValue(value: string) {
        const newCreateData = {
            ...createData,
            value: value
        }
        setCreateData(newCreateData)
        props.onSetCreateData(newCreateData)
    }

    const handleSelectBool = (data: any) => {
        let newValue = false;
        switch (data) {
            case true:
                newValue = true;
                break;
            case false:
                newValue = false;
                break;
        }

        const newCreateData = {
            ...createData,
            value: newValue
        }
        setCreateData(newCreateData)
        props.onSetCreateData(newCreateData)
    }

    return (
        <div>
            <div className="d-flex flex-jbetween mt-4">
                <span>Type: </span>
                <span>{props.modalDataInput.type}</span>
            </div>
            <div className="d-flex flex-jbetween mt-4">
                <span>Company Number: </span>
                <Input style={{width: 120}}
                       defaultValue={createData.company}
                       onChange={(e: any) => onSetCompany(e.target.value)}
                       value={createData.company}></Input>
            </div>
            
            <div className="d-flex flex-jbetween mt-2">
                <span>Value {createData.type}</span>
                {
                    (createData.type === 'String'
                    || createData.type === 'Integer'
                    || createData.type === 'Double'
                    || createData.type === 'Uri'
                    || createData.type === 'Guid')
                    && <Input style={{width: 300}}
                              defaultValue={createData.value as string}
                              onChange={(e: any) => onSetValue(e.target.value)}
                              value={createData.value as string}></Input>
                }

                {   
                    createData.type === 'Boolean'
                    && <Select
                        style={{width: 120}}
                        onChange={handleSelectBool}
                        defaultValue={createData.value}
                        options={[
                            {
                                value: true,
                                label: 'True'
                            }, {
                                value: false,
                                label: 'False'
                            }
                        ]}>

                    </Select>
                }

            </div>
        </div>

    )
}