import React from "react";
import _ from "lodash";

export interface TreeNode {
    key: number;
    name: string;
    description?: string;
    area?: string;
    tags?: string[];
    type?: string;
    preferStoreAsJson?: boolean;
    defaultValue?: any
    allowNull?: boolean;
    children?: TreeNode[];
}

export enum rowType {
    leaf,
    branch
}

export interface DataType {
    key: any;
    name: string;
    description: string;
    tags?: string[];
    type?: string;
    defaultValue?: any
    children?: DataType[];
    changed?: boolean;
}


function createNewNode(name: string, info: any, parentNode: TreeNode, rootId: string): TreeNode {
    const newNode: TreeNode = {
        key: Number(parentNode.key.toString() + '1') + (parentNode.children?.length ?? 0),
        name: name,
        area: rootId,
    };

    if (info) {
        mapInfoData(newNode, info);
    }
    
  
    if (!parentNode.children) {
      parentNode.children = [];
    }
    parentNode.children.push(newNode);
  
    return newNode;
}

export function rearrangeSourceIntoRoot(source: any, rootId: string) {
    let currentNode: TreeNode = {
        key: 1,
        name: '',
        description: '',
    };

    let rootNodeArr = []
    let rootCounter: any[] = []
    
    let targetRootNode = _.clone(currentNode);
    console.log('source', source)
    for (let sourceKey in source) {
        const keyArr = sourceKey.split(':')
        // console.log('key arr', keyArr)
        // if (keyArr.indexOf('Include') > -1) {
        //     debugger;
        // }
        let rootindex = rootCounter.findIndex(v => v === keyArr[0])
        if (rootindex === -1) {
            rootCounter.push(keyArr[0])
            currentNode.key = rootCounter.length
            currentNode.area = rootId
            let targetRootNode = _.clone(currentNode);
            rootNodeArr.push(targetRootNode)
            rootindex = rootCounter.length - 1
        } else {
           // targetRootNode = _.clon
        }

        // generateTreeNode(keyArr, source[sourceKey], currentNode, false)
        
        generateTreeNode(keyArr, source[sourceKey], rootNodeArr[rootindex], rootId)
    }

    console.log('root node arr', rootNodeArr)
    return rootNodeArr;
}
  
export function generateTreeNode(keyArr: string[], info: any, parentNode: TreeNode, rootId: string): TreeNode {
    const name = keyArr[0];
    // handle first layer of init object in root array. the object do not have a name.
    if (parentNode && parentNode.name === '') {
        parentNode.name = name;
        if (keyArr.length > 1) {
            return generateTreeNode(keyArr.slice(1), info, parentNode, rootId);
        }
        mapInfoData(parentNode, info);
        return parentNode
    }


    if (parentNode && parentNode.name !== '' && parentNode.name === name) {
        if (keyArr.length > 1) {
            return generateTreeNode(keyArr.slice(1), info, parentNode, rootId);
        }
        console.log('in create new node when come back', keyArr)
        return createNewNode(name, info, parentNode, rootId);
    }

    if (keyArr.length <= 1) {
        return createNewNode(name, info, parentNode, rootId);
    }

    const existChild = parentNode?.children?.find(child => child.name === name);

    if (existChild) {
        if (!existChild.children) {
            existChild.children = [];
        }
        return generateTreeNode(keyArr.slice(1), info, existChild, rootId);
    } else {
        let newNode = createNewNode(name, null, parentNode, rootId);
        return generateTreeNode(keyArr.slice(1), info, newNode, rootId);
    }
}

// map data from into to currentNode. 
// this function manipulate data in currentNode
function mapInfoData(currentNode: any, info: any): void {
    currentNode.description = info.description
    if (info.tags) currentNode.tags = info.tags
    if (info.valueMetadata?.valueType) currentNode.type = info.valueMetadata.valueType
    if (!_.isNil(info.valueMetadata?.defaultValue)) currentNode.defaultValue = info.valueMetadata.defaultValue
    if (info.valueMetadata?.allowNull !== null && info.valueMetadata?.allowNull !== undefined) {
        currentNode.allowNull = info.valueMetadata.allowNull
    }

    if(!_.isNil(info.preferStoreAsJson)) currentNode.preferStoreAsJson = info.preferStoreAsJson
}

export function generateTreeNodeV2(source: any[]) {
    console.log('source', source)
    let finalArr: any[] = []
    for(let rawNodeKey in source) {
        console.log(source[rawNodeKey])
        const splitedRawKey = rawNodeKey.split(':')
        const rootObject = findOrCreateRootObj(splitedRawKey[0], finalArr)

        if (splitedRawKey.length === 1) {
            mapInfoData(rootObject, source[rawNodeKey])
            continue;
        }

        splitedRawKey.splice(1, 0)

        let target: any; 
        splitedRawKey.map((key, index) => {
            console.log('key', key)
            if (index === 0) {
                target = rootObject
            }
            if (index < splitedRawKey.length - 1) {
                if (!target.children) {
                    target.children = []
                    const newChild = {name: key}
                    target.children.push(newChild)
                    target = newChild;
                } else {
                    const foundBranch = findOrCreateRootObj(key, target.children)
                    target = foundBranch;
                }
            } else {
                console.log('target', target)
                mapInfoData(target, source[rawNodeKey])
            }
        })
        console.log('rootObject', rootObject)

    }
    console.log('finalArr', finalArr)
}

function findOrCreateRootObj (name: string, finalArr: any[]) {
    const foundObj = finalArr.find(obj => {
        return obj.name === name
    })

    if (foundObj) {
        return foundObj
    } else {
        const newRootObject = {
            key: finalArr.length + 1,
            name: name
        }
        finalArr.push()
        return newRootObject;
    }
}

export function insertBranch(tree: any[], selectedNode: any, newNode: any) {
    console.log('newNode', newNode)
    let newObj = {}
    if (selectedNode.children && Array.isArray(selectedNode.children) && selectedNode.children.length > 0) {
        newObj = {
            key: selectedNode.children[selectedNode.children.length - 1].key + 1,
            name: newNode.name,
            description: newNode.description,
        }
        selectedNode.children.push(newObj)
    } else {
        newObj = {
            key: Number(selectedNode.key.toString() + 1),
            name: newNode.name,
            description: newNode.description,
        }
        selectedNode.children = [newObj]
    }

    insertObject(selectedNode.key, selectedNode, tree )
    resetKeyInTree(tree[0], tree[0].children, false)
    return tree;
}

function insertObject(key: number, newNode: any, arr: any[]): boolean {
    for (const node of arr) {
        if (node.key === key) {
            node.children = _.cloneDeep(newNode.children)
            return true;
        }
        if (node.children && insertObject(key, newNode, node.children)) {
            return true;
        }
    }
    return false;
}

export function insertLeaf(tree: any[], selectedNode: any, newNode: any) {
    if (selectedNode.children === undefined) {
        selectedNode.children = []
        newNode.key = selectedNode.key + '1'
        newNode.allowNull = false;
        newNode.preferStoreAsJson = false;
        newNode.tags  = [];

        selectedNode.children.push(newNode)
    } else if (selectedNode.children && Array.isArray(selectedNode.children)) {
        newNode.key = selectedNode.children[selectedNode.children.length - 1].key + 1
        newNode.allowNull = false;
        newNode.preferStoreAsJson = false;
        newNode.tags  = [];

        selectedNode.children.push(newNode)
    } else {
        throw new Error('selectedNode.children is not an array')
    }
    insertObject(selectedNode.key, selectedNode, tree )
    resetKeyInTree(tree[0], tree[0].children, false)

    return tree;
}

export function removeBranch(tree: any[], node: any) {
    let keyArr = node.key.toString().split('')
    let target = tree[Number(keyArr[0]) - 1];

    keyArr.map((key: string, index: number) => {
        if (index === 0) {
            //
        } else if (index < keyArr.length - 1) {
            if (target?.children) {
                target = target.children[Number(key) - 1]
            }

        }
    })
    target.children.splice(Number(keyArr[keyArr.length - 1]) - 1, 1)
    resetKeyInTree(tree[Number(keyArr[0])- 1], tree[Number(keyArr[0])- 1].children, false)
    return tree;
}

export function resetKeyInTree(branchNode: any, children: any[], sort: boolean) {
    let keyRoot = branchNode.key.toString()

    if (sort) {
        children.sort((childA: any, childB: any) => {
            if (childA.name > childB.name) {
                return 1;
            } else if (childA.name < childB.name) {
                return -1;
            } else {
                return 0;
            }
        })
    }

    children.forEach((child: any, index: number) => {
         child.key = Number(keyRoot + (index + 1))
         if (child.children) {
             resetKeyInTree(child, child.children, sort)
         }
    })
    return children
}

export function resetKeyInTreeArray(treeNodeArr: any[], keyRoot: string | number = 0) {
    treeNodeArr.sort((childA: any, childB: any) => {
        if (childA.name > childB.name) {
            return 1;
        } else if (childA.name < childB.name) {
            return -1;
        } else {
            return 0;
        }
    })

    treeNodeArr.forEach((node: any, index: number) => {
        if (keyRoot != 0){
            node.key = Number(keyRoot.toString() + (index + 1))
        } else {
            node.key = Number(keyRoot + (index + 1))
        }
        
        if (node.children) {
            resetKeyInTreeArray(node.children, node.key)
        }
   })
   return treeNodeArr
    
}

export function formatTreeNodeForBackend(treeNode: any, treeNodeBk: any) {
    let finalArr = flattenArray(treeNode)
    return finalArr
}

function flattenArray(arr: any) {
    let flatArray: any[] = [];

    function flatten(node: any, name: string) {
        let obj: any = {};
        if (!name && node.name) {
            obj[node.name] = {};
            name = node.name;
        } else {
            obj[name] = {};
        }

        if (node.description) obj[name].description = node.description;
        if (node.tags) obj[name].tags = node.tags;
        if (node.type) obj[name].valueMetadata = {
            valueType: node.type
        };
        if (!_.isNil(node.preferStoreAsJson)) obj[name].preferStoreAsJson = node.preferStoreAsJson
        if (!_.isNil(node.defaultValue)) obj[name].valueMetadata.defaultValue = node.defaultValue
        if (!_.isNil(node.allowNull)) obj[name].valueMetadata.allowNull = node.allowNull
        
      
        flatArray.push(obj)

        if (!node.children) {
            return;
        }

        node.children.forEach((child: any) => {
            if (name) {
                flatten(child, name + ':' + child.name);
            } else {
                flatten(child, child.name);
            }
        });
    }

    arr.forEach((node: any) => {
        flatten(node, '');
    })
    // flatten(arr[0], '');
    return flatArray;
}

export function leafBranchValidate(modalDataChanged: any) {
    if ((!_.isNil(modalDataChanged.defaultValue) || modalDataChanged.type) && !modalDataChanged.children) {
        return rowType.leaf
    } else if (!modalDataChanged.defaultValue && !modalDataChanged.type) {
        return rowType.branch
    } else {
        return 'error'
    }
}

export function findNodeByKey(key: string | number, source: any) {
    console.log('key', key)
    let keyStringArr = key.toString().split('')
    // keyStringArr.pop()

    console.log('source', source)

    // let keyArr = key.toString().split('')
    let target = source[0];

    keyStringArr.map((key: string, index: number) => {
        if (index === 0) {
            //
        } else if (index < keyStringArr.length - 1) {
            if (target.children) {
                target = target.children[Number(key) - 1]
            }

        }
    })
    console.log('target', target)
    return target
}